import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { SessionService } from '@app/core/services/session.service';
import { HelperService } from '@app/core/services/helper.service';
import { ErrorService } from '@app/core/services/error.service';

import { AppError } from '@app/core/models/app-error.model';
import { Dealer } from '@app/core/models/dealer.model';

@Component({
	selector: 'cng-error',
	templateUrl: './error.component.html',
	styleUrls: ['./error.component.less'],
})
export class ErrorComponent implements OnInit, OnDestroy {
	public dealer: Dealer = this.session_service.getDealer();
	public error: AppError;
	public lte_xs: boolean = false;
	public report_sent: boolean = false;

	private destroyed$: Subject<boolean> = new Subject<boolean>();

	constructor(
		private session_service: SessionService,
		private helper_service: HelperService,
		private error_service: ErrorService
	) {}

	ngOnInit(): void {
		this.error_service.error$.pipe(takeUntil(this.destroyed$)).subscribe((error: AppError) => {
			this.session_service.stopAppLoading();
			this.error = error;
			this.session_service.setTitle(this.error.title);
		});

		this.session_service.dealer$.pipe(takeUntil(this.destroyed$)).subscribe((dealer: Dealer) => {
			this.dealer = dealer;
		});

		this.checkScreenWidth();
	}

	@HostListener('window:resize')
	checkScreenWidth(): void {
		this.lte_xs = this.helper_service.isScreenWidth('<=', 'xs');
	}

	ngOnDestroy(): void {
		this.destroyed$.next(true);
	}
}
