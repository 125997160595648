<!--  MAIN APP CONTAINER  -->

<div id="app" class="app-container" [ngClass]="{'hidden' : app_loading}">
	<router-outlet name="primary"></router-outlet>
	<router-outlet name="modal"></router-outlet>
	<cng-toasts></cng-toasts>
	<cng-error></cng-error>
</div>

<!--  END MAIN APP CONTAINER  -->

<!--  APP LOADING  -->

<div *ngIf="app_loading" class="app-loading">
	<div class="loading-spinner"></div>
</div>

<!--  END APP LOADING  -->


