import { Component } from '@angular/core';

import { ModalService } from '@app/core/services/modal.service';
import { SessionService } from '@app/core/services/session.service';

import { Dealer } from '@app/core/models/dealer.model';

@Component({
	selector: 'cng-dealer-contact-modal',
	templateUrl: './dealer-contact.component.html',
	styleUrls: ['./dealer-contact.component.less'],
})
export class DealerContactModalComponent {
	public data: any;
	public dealer: Dealer = this.session_service.getDealer();

	/**
	 * Constructs component and injects dependencies.
	 *
	 * @param {SessionService} session_service
	 * @param {ModalService} modal_service
	 */
	constructor(private session_service: SessionService, private modal_service: ModalService) {
		this.data = this.modal_service.data || {};
		this.modal_service.setConfig({
			name: 'dealer_contact_modal',
			title: 'Contact Us',
			height: 'full',
		});
	}
}
