export class Toast {
	
	message: string;
	type: 'success' | 'error' | 'warning' | 'info';
	duration: number;
	icon: string;
	show?: boolean = false;
	
	constructor(toast: Toast) {
		Object.assign(this, toast);
	}
}
