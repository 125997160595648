import { Injectable } from '@angular/core';

import * as CookieHelper from '../helpers/cookie.helper';

@Injectable()
export class StorageService {
	supportsLS: boolean = false;

	/**
	 * Constructs class and injects dependencies.
	 */
	constructor() {
		this.supportsLS = this.supportsLocalStorage();
	}

	/**
	 * Saves item into cookie or local storage.
	 *
	 * @param {string} key
	 * @param {string} value
	 * @param {number} expiryDays
	 */
	setItem(key: string, value: string, expiryDays?: number): void {
		if (this.supportsLS) {
			this.setLSItem(key, value);
			return;
		}

		this.setCookie(key, value, expiryDays);
	}

	/**
	 * Gets item from cookie or local storage.
	 *
	 * @param {string} key
	 * @returns {string}
	 */
	getItem(key: string): string {
		if (this.supportsLS) {
			return this.getLSItem(key);
		}

		return this.getCookie(key);
	}

	/**
	 * Gets all cookies / local storage items that start with check key.
	 *
	 * @param {string} checkKey
	 */
	getAllThatStartWith(checkKey: string): { key: string; value: string }[] {
		const return_items: { key: string; value: string }[] = [];

		if (this.supportsLS) {
			for (let i: number = 0; i < localStorage.length; i++) {
				const lsKey: string = localStorage.key(i);

				if (lsKey.indexOf(checkKey) !== -1) {
					return_items.push({ key: lsKey, value: this.getLSItem(lsKey) });
				}
			}
		} else {
			const cookies: any = this.getCookiesThatStartWith(checkKey);

			Object.keys(cookies).forEach((cookieKey: string) => {
				return_items.push({ key: cookieKey, value: this.getCookie(cookieKey) });
			});
		}

		return return_items;
	}

	/**
	 * Removes item from cookie or local storage.
	 *
	 * @param {string} key
	 */
	removeItem(key: string): void {
		if (this.supportsLS) {
			this.removeLSItem(key);
			return;
		}

		this.removeCookie(key);
	}

	/**
	 * Removes all cookies / local storage items that start with check key.
	 *
	 * @param {string} checkKey
	 */
	removeAllThatStartWith(checkKey: string): void {
		if (this.supportsLS) {
			for (let i: number = 0; i < localStorage.length; i++) {
				const lsKey: string = localStorage.key(i);

				if (lsKey.indexOf(checkKey) !== -1) {
					this.removeLSItem(lsKey);
				}
			}
		} else {
			const cookies: any = this.getCookiesThatStartWith(checkKey);

			Object.keys(cookies).forEach((cookieKey: string) => {
				this.removeCookie(cookieKey);
			});
		}
	}

	//  COOKIES

	/**
	 * Gets all cookies that start with key.
	 *
	 * @param {string} key
	 *
	 * @returns {}
	 */
	getCookiesThatStartWith(key: string): any {
		return CookieHelper.getCookiesThatStartWith(key);
	}

	/**
	 * Sets cookie.
	 *
	 * @param {string} key
	 * @param {string} value
	 * @param {number} expiryDays
	 */
	setCookie(key: string, value: string, expiryDays: number = 7): void {
		return CookieHelper.setCookie(key, value, expiryDays);
	}

	/**
	 * Gets cookie.
	 *
	 * @param {string} key
	 * @returns {string}
	 */
	getCookie(key: string): string {
		return CookieHelper.getCookie(key);
	}

	/**
	 * Removes cookie.
	 *
	 * @param key
	 */
	removeCookie(key: string): void {
		this.setCookie(key, '', -1);
	}

	//  LOCAL STORAGE

	/**
	 * Checks for local storage support.
	 *
	 * @returns {boolean}
	 */
	supportsLocalStorage(): boolean {
		const test: string = 'test';
		try {
			localStorage.setItem(test, test);
			localStorage.removeItem(test);
			return true;
		} catch (e) {
			return false;
		}
	}

	/**
	 * Sets local storage item.
	 *
	 * @param {string} key
	 * @param {string} value
	 */
	setLSItem(key: string, value: string): void {
		localStorage.setItem(key, value);
	}

	/**
	 * Gets local storage item.
	 *
	 * @param {string} key
	 * @returns {string}
	 */
	getLSItem(key: string): string {
		return localStorage.getItem(key);
	}

	/**
	 * Removes local storage item.
	 *
	 * @param {string} key
	 */
	removeLSItem(key: string): void {
		localStorage.removeItem(key);
	}
}
