import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';

@Injectable()
export class LoqateService {
	private base_url: string = 'https://services.postcodeanywhere.co.uk/';

	constructor(private http_client: HttpClient) {}

	searchAddress(search_term: string): Observable<any> {
		let params: HttpParams = new HttpParams();
		params = params.append('key', environment.loqate_api_key);
		params = params.append('searchterm', search_term);

		return this.http_client.post(this.base_url + 'CapturePlus/Interactive/Find/v2.10/json3.ws', null, {
			params,
			observe: 'response',
		});
	}

	getAddressById(id: string): Observable<any> {
		let params: HttpParams = new HttpParams();
		params = params.append('key', environment.loqate_api_key);
		params = params.append('id', id);

		return this.http_client.post(this.base_url + '/CapturePlus/Interactive/Retrieve/v2.10/json3.ws', null, {
			params,
			observe: 'response',
		});
	}

	searchBank(search_account: string, search_sortcode: string): Observable<any> {
		let params: HttpParams = new HttpParams();
		params = params.append('key', environment.loqate_api_key);
		params = params.append('accountnumber', search_account);
		params = params.append('sortcode', search_sortcode);

		return this.http_client.post(this.base_url + 'BankAccountValidation/Interactive/Validate/v2/json3.ws', null, {
			params,
			observe: 'response',
		});
	}
}
