export class TrackingData {
	journey: string;
	utm_campaign: string;
	utm_source: string;
	utm_medium: string;
	ga_client_id: string;
	uri: string;
	domain: string;

	constructor(trackingData: TrackingData) {
		Object.assign(this, trackingData);
	}
}
