import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { forkJoin, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';

import { ConfigureService } from '@app/configure/services/configure.service';
import { StorageService } from '@app/core/services/storage.service';

import { Resolvers } from '@app/core/resolvers/resolvers';

@Injectable()
export class ConfigureVehicleResolver implements Resolve<any> {
	/**
	 * Constructs class and injects dependencies.
	 *
	 * @param {ConfigureService} configure_service
	 * @param {StorageService} storage_service
	 * @param {Resolvers} resolvers
	 * @param {Router} router
	 */
	constructor(
		private configure_service: ConfigureService,
		private storage_service: StorageService,
		private resolvers: Resolvers,
		private router: Router
	) {}

	/**
	 * Resolves route with vehicle and dealer.
	 *
	 * @param {ActivatedRouteSnapshot} activated_route_snapshot
	 *
	 * @returns {Observable}
	 */
	resolve(activated_route_snapshot: ActivatedRouteSnapshot): Observable<any> {
		const vehicle_id: number = activated_route_snapshot.params['vehicle_id'];
		const vehicle_enquiry_hash: string = this.storage_service.getItem('vehicle-' + vehicle_id);
		let step_mode: string = activated_route_snapshot.queryParams['step_mode'];
		let pex_enquiry_hash: string = activated_route_snapshot.queryParams['pex_enquiry_hash'];

		if (step_mode === 'partex') {
			step_mode = 'part_exchange';
		}

		if (step_mode !== 'part_exchange' && step_mode !== 'reserve') {
			step_mode = 'full';
		}

		this.configure_service.setStepMode(step_mode);

		if (!!vehicle_enquiry_hash) {
			this.router.navigate(['/configure', 'e', vehicle_enquiry_hash], {
				queryParams: [{ step_mode: this.configure_service.getStepMode() }],
			});
			return of(null);
		}

		const requests = [
			this.resolvers.getVehicle(vehicle_id),
			this.resolvers.getDealer(new HttpParams().append('vehicle_id', vehicle_id.toString())),
		];

		if (pex_enquiry_hash) {
			requests.push(
				this.resolvers.getEnquiry(pex_enquiry_hash, this.configure_service.getStepMode(), vehicle_id),
			);
		}

		return forkJoin(requests);
	}
}
