import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { FinanceManagerService } from '@app/finance-manager/services/finance-manager.service';
import { SessionService } from '../services/session.service';

import { Resolvers } from '@app/core/resolvers/resolvers';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class FinanceManagerEnquiryResolver implements Resolve<any> {
	constructor(
		private resolvers: Resolvers,
		private finance_manager_service: FinanceManagerService,
		private session_service: SessionService
	) {}

	resolve(activated_route_snapshot: ActivatedRouteSnapshot): Observable<any> {
		const enquiry_hash: string = activated_route_snapshot.params['enquiry_hash'];
		this.session_service.app_loading = true;
		this.session_service.app_loading$.next(true);
		return forkJoin([
			this.finance_manager_service.getEnquiry(enquiry_hash),
			this.resolvers.getDealer(new HttpParams().append('enquiry_hash', enquiry_hash)),
		]);
	}
}
