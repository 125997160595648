import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

import { DataLayerService } from './data-layer.service';
import { HelperService } from './helper.service';

import { ModalAction } from '../models/modal-action.model';
import { Modal } from '../models/modal.model';

@Injectable()
export class ModalService {
	config$: Subject<Modal> = new Subject<Modal>();
	config: Modal = null;

	data: any;
	bodyScrollTop: number = 0;

	/**
	 * Constructs class and injects dependencies.
	 *
	 * @param {Router} router
	 * @param {HelperService} helper_service
	 * @param {DataLayerService} data_layer_service
	 */
	constructor(
		private router: Router,
		private helper_service: HelperService,
		private data_layer_service: DataLayerService
	) {}

	/**
	 * Closes any already open modals and triggers navigate method.
	 *
	 * @param {string[]} route
	 * @param data
	 * @param {boolean} showInUrlBar
	 */
	show(route: string[], data: any = null, showInUrlBar: boolean = false): void {
		if (this.config !== null) {
			this.close().then(() => {
				this.navigate(route, data, showInUrlBar);
			});
		} else {
			this.navigate(route, data, showInUrlBar);
		}
	}

	/**
	 * Shows modal by navigating to modal route on the modal outlet.
	 *
	 * @param {string[]} route
	 * @param data
	 * @param {boolean} showInUrlBar
	 */
	navigate(route: string[], data: any = null, showInUrlBar: boolean = false): void {
		this.data = data;
		const skipLocationChange: boolean = data ? true : !showInUrlBar;
		this.router.navigate([{ outlets: { modal: 'dialog/' + route.join('/') } }], { skipLocationChange }).then(() => {
			if (skipLocationChange) {
				this.data_layer_service.push({ event: 'modalOpened', modalUrl: this.router.url });
			}
		});
	}

	/**
	 * Shows confirm modal.
	 *
	 * @param {Modal} modalConfig
	 */
	showConfirm(modalConfig: Modal): void {
		this.config = Object.assign(new Modal(), modalConfig);

		this.router.navigate([{ outlets: { modal: 'dialog/confirm' } }], { skipLocationChange: true }).then(() => {
			this.setShow(true);
		});
	}

	/**
	 * Sets modal config.
	 *
	 * @param {Modal} modalConfig
	 */
	setConfig(modalConfig: Modal): void {
		this.config = Object.assign(this.config || new Modal(), modalConfig);
		this.config$.next(this.config);
	}

	/**
	 * Sets 'show' value of modal component.
	 *
	 * @param {boolean} show
	 */
	setShow(show: boolean): void {
		if (this.config) {
			this.config.show = show;
			this.config$.next(this.config);

			if (show) {
				this.bodyScrollTop = window.pageYOffset;
				this.helper_service.scrollLock(this.bodyScrollTop, 200);
			} else {
				this.helper_service.scrollUnlock(this.bodyScrollTop);
			}
		}
	}

	/**
	 * Triggers modal action.
	 *
	 * @param {string} action_id
	 */
	triggerAction(action_id: string): void {
		const triggeredAction: ModalAction = this.config.actions.filter((action: ModalAction) => {
			return action.id === action_id;
		})[0];

		triggeredAction.loading = triggeredAction.showLoading;
		triggeredAction.click(triggeredAction);
	}

	/**
	 * Sets action config.
	 *
	 * @param {string} action_id
	 * @param config
	 */
	setAction(action_id: string, config: any): void {
		Object.assign(
			this.config.actions.filter((action: ModalAction) => {
				return action.id === action_id;
			})[0],
			config
		);

		// actionToSet = Object.assign(actionToSet, config);
	}

	/**
	 * Closes modal.
	 */
	close(): Promise<any> {
		this.setShow(false);

		return new Promise((resolve: any): void => {
			setTimeout(() => {
				this.router.navigate([{ outlets: { modal: null } }]).then(() => {
					this.config = null;
					this.config$.next(null);
					this.data = null;

					resolve();
				});
			}, 250);
		});
	}
}
