import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';

import { UIPopoverComponent } from './popover.component';

@NgModule({
	declarations: [UIPopoverComponent],
	imports: [CommonModule],
	exports: [UIPopoverComponent],
})
export class UIPopoverModule {}
