import { Injectable } from '@angular/core';

import { ModalService } from '@app/core/services/modal.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { ConfigureService } from './configure.service';

const steps_to_reserve: string = 'reserve';

@Injectable()
export class SkipFinanceService {
	onToggleFinanceState: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
	onDidSetFinanceFacilityType: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	is_reserved: boolean = false;
	has_finance_step: boolean = false;
	/**
	 * Constructs class and injects dependencies.
	 *
	 * @param {ModalService} modal_service
	 */
	constructor(private modal_service: ModalService, private configure_service: ConfigureService) {
		this.configure_service.configure_data$.subscribe((configure_data) => {
			this.is_reserved = configure_data.steps.payment.data.reservation.reserved;

			this.has_finance_step =
				configure_data.meta.finance.available &&
				configure_data.steps.finance_options.data &&
				configure_data.steps.finance_options.data.length > 0;
		});
	}

	get financeToggleState(): any | Observable<boolean> {
		return this.onToggleFinanceState.getValue();
	}

	get financeFacilityType(): any | Observable<boolean> {
		return this.onDidSetFinanceFacilityType.getValue();
	}

	dispose(): void {
		this.onToggleFinanceState.next(false);
		this.onDidSetFinanceFacilityType.next(true);
		this.onToggleFinanceState.complete();
		this.onDidSetFinanceFacilityType.complete();
	}

	/**
	 * The modal to select the finance options
	 *
	 * @param steps
	 * @return void
	 */
	onJumpSteps(steps: string, checkState: any | Function): void {
		if (this.is_reserved) return checkState(true);
		const isSkipping: boolean =
			steps === steps_to_reserve && this.financeToggleState && !this.financeFacilityType && this.has_finance_step;
		if (isSkipping) this.modal_service.show(['configure/finance-skip']);
		checkState(!isSkipping);
	}
}
