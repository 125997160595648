<!--  MODAL  -->

<!--  OVERLAY  -->

<div
	class="overlay"
	[class.show]="config.show"
	[class.can-dismiss]="config.canDismiss && config.canDismissWithOverlay"
	[attr.data-element-click]="'modal_background__' + config.name + '__area_outside_modal'"
	(click)="onCloseWithOverlay()"
></div>

<!--  END OVERLAY  -->

<!--  MODAL FRAME  -->

<div
	class="modal-frame"
	[class.show]="config.show"
	[ngClass]="['size-' + config.size + ' height-' + config.height]"
	#modalFrame
>
	<!--  HEADER  -->

	<div class="modal-header pri-bg">
		<h2 class="pri-fg">
			{{ config.title }}
		</h2>
		<i
			*ngIf="config.canDismiss"
			class="icon-button icon-cross pri-fg"
			[attr.data-element-click]="'close_icon__' + config.name + '__x_button'"
			(click)="onClose()"
		></i>
	</div>

	<!--  END HEADER  >

	<!--  BODY  -->

	<div class="modal-body" [class.has-footer]="config.actions.length > 0">
		<router-outlet></router-outlet>
	</div>

	<!--  END BODY  -->

	<!--  FOOTER  -->

	<div *ngIf="config.actions.length > 0" class="modal-footer">
		<!--  MODAL ACTIONS LOOP  -->

		<a
			*ngFor="let action of config.actions"
			class="button"
			[class.large-button]="action.large"
			[class.single]="action.grow && config.actions.length === 1"
			[class.double]="action.grow && config.actions.length === 2"
			[class.loading]="action.showLoading && action.loading"
			[class.positive-button]="action.bias === 'positive'"
			[class.negative-button]="action.bias === 'negative'"
			[attr.href]="action.path"
			[attr.target]="action.path ? action.target : null"
			[attr.data-element-click]="'button__' + config.name + '__' + action.bias + '-button[' + action.name + ']'"
			(click)="onClick(action)"
		>
			<span *ngIf="action.showLoading" class="loading-spinner"></span>
			{{ action.name }}
		</a>

		<!--  END MODAL ACTIONS LOOP  -->
	</div>

	<!--  END FOOTER  -->
</div>

<!--  END MODAL FRAME  -->

<!--  END MODAL  -->
