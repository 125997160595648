import { Component, OnDestroy, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { ToastService } from '@app/core/services/toast.service';

import { Toast } from '@app/core/models/toast.model';

@Component({
	selector: 'cng-toasts',
	templateUrl: './toasts.component.html',
	styleUrls: ['./toasts.component.less'],
})
export class ToastsComponent implements OnInit, OnDestroy {
	public toasts: Toast[] = [];

	private destroyed$: Subject<boolean> = new Subject<boolean>();

	/**
	 * Constructs component and injects dependencies.
	 *
	 * @param {SessionService} session_service
	 * @param {ToastService} toast_service
	 */
	constructor(private toast_service: ToastService) {}

	/**
	 * Lifecycle hook: Subscribes to toast service.
	 */
	ngOnInit(): void {
		this.toast_service.toasts$.pipe(takeUntil(this.destroyed$)).subscribe((toasts: Toast[]) => {
			this.toasts = toasts;
		});
	}

	/**
	 * Removes toast.
	 *
	 * @param {Toast} toast
	 */
	remove(toast: Toast): void {
		this.toast_service.remove(toast);
	}

	/**
	 * Lifecycle hook: Destroys component and any open subscriptions.
	 */
	ngOnDestroy(): void {
		this.destroyed$.next(true);
	}
}
