<div class="dealer-contact">
	<div class="dealer-contact-details">
		<!--  QUOTE REFERENCE  -->

		<div *ngIf="data.quote_ref" class="quote-ref">
			<ul class="key-value-list">
				<li>
					<div class="key">Quote Reference:</div>
					<div class="value">
						<span class="number-plate number-plate-large">{{ data.quote_ref }}</span>
					</div>
				</li>
			</ul>
		</div>

		<!-- END QUOTE REFERENCE  -->

		<!--  CONTACT LIST  -->

		<ui-contact-list [allow_callback]="data.callback_enabled"></ui-contact-list>

		<!-- END CONTACT LIST  -->
	</div>
	<div class="dealer-contact-map">
		<!--  MAP IFRAME  -->

		<iframe id="address-map" [attr.src]="dealer.google_maps_embed" class="google-map"></iframe>

		<!--  END MAP IFRAME  -->
	</div>
</div>
