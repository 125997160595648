import { Component } from '@angular/core';

import { ModalService } from '@app/core/services/modal.service';
import { SessionService } from '@app/core/services/session.service';

import { ModalAction } from '@app/core/models/modal-action.model';
import { Modal } from '@app/core/models/modal.model';
import { Dealer } from '@app/core/models/dealer.model';

@Component({
	selector: 'cng-dealer-map-modal',
	templateUrl: './dealer-map.component.html',
	styleUrls: ['./dealer-map.component.less'],
})
export class DealerMapModalComponent {
	public dealer: Dealer = this.session_service.getDealer();

	/**
	 * Constructs component and injects dependencies.
	 *
	 * @param {SessionService} session_service
	 * @param {ModalService} modal_service
	 */
	constructor(private session_service: SessionService, private modal_service: ModalService) {
		this.modal_service.setConfig(
			new Modal({
				name: 'dealer_map_modal',
				title: 'Visit Us',
				size: 'md',
				height: 'full',
			})
		);
	}
}
