import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, Inject } from '@angular/core';
import { APP_BASE_HREF } from '@angular/common';
import { Router } from '@angular/router';

import { CoreModule } from './core/core.module';

import { AppRouterModule } from './app.router.module';

import { AppErrorHandler } from '@app/app.error-handler';

import { AppComponent } from './app.component';

import { DomainHelper } from './core/helpers/domain.helper';

import { ApmModule, ApmService, ApmErrorHandler } from '@elastic/apm-rum-angular';
import { environment } from '@environments/environment';

@NgModule({
	declarations: [AppComponent],
	imports: [ApmModule, BrowserModule, NoopAnimationsModule, CoreModule, AppRouterModule],
	providers: [
		{
			provide: ErrorHandler,
			useClass: AppErrorHandler,
		},
		{
			provide: APP_BASE_HREF,
			useValue: `//${window.location.host}${DomainHelper.getBaseHref()}`,
		},
		ApmService,
		{
			provide: ErrorHandler,
			useClass: ApmErrorHandler,
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {
	constructor(service: ApmService) {
		// API is exposed through this apm instance
		service.init({
			serviceName: 'click-engage',
			serverUrl: 'https://ops-cluster.apm.eu-west-2.elastic.clickdealer.tech',
			environment: environment.apm_name,
			distributedTracingOrigins: [environment.api_url],
		});
	}
}
