import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';

import { Resolvers } from '@app/core/resolvers/resolvers';

@Injectable()
export class DealerResolver implements Resolve<any> {
	/**
	 * Constructs class and injects dependencies.
	 *
	 * @param {Resolvers} resolvers
	 */
	constructor(private resolvers: Resolvers) {}

	/**
	 * Resolves route with dealer.
	 *
	 * @returns {Observable}
	 */
	resolve(): Observable<any> {
		return this.resolvers.getDealer();
	}
}
