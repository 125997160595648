import { ModalAction } from '@app/core/models/modal-action.model';

export class Modal {
	name: string;
	title: string;
	show?: boolean = false;
	size?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' = 'xs';
	height?: 'content' | 'full' = 'content';
	canDismiss?: boolean = true;
	canDismissWithOverlay?: boolean = this.canDismiss;
	content?: string[] = [];
	actions?: ModalAction[] = [];

	constructor(modal?: Modal) {
		if (modal) {
			// Ensure developer hasn't instantiated class with show set to true.
			// This is the job of the UIModalComponent to ensure transition.

			modal.show = false;
		}

		Object.assign(this, modal);
	}
}
