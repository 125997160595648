import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { UITooltipDirective } from './tooltip.directive';

@NgModule({
	declarations: [UITooltipDirective],
	imports: [CommonModule],
	exports: [UITooltipDirective],
})
export class UITooltipModule {}
