import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';

import { Resolvers } from '@app/core/resolvers/resolvers';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class EnquiryAuthenticateResolver implements Resolve<any> {
	/**
	 * Constructs resolvers and injects dependencies.
	 *
	 * @param {Resolvers} resolvers
	 */
	constructor(private resolvers: Resolvers) {}

	/**
	 * Resolves route with obfuscated customer and dealer.
	 *
	 * @param {ActivatedRouteSnapshot} activated_route_snapshot
	 *
	 * @returns {Observable}
	 */
	resolve(activated_route_snapshot: ActivatedRouteSnapshot): Observable<any> {
		const enquiry_hash: string = activated_route_snapshot.params['enquiry_hash'];

		return forkJoin([
			this.resolvers.getObfuscatedCustomer(enquiry_hash),
			this.resolvers.getDealer(new HttpParams().append('enquiry_hash', enquiry_hash)),
		]);
	}
}
