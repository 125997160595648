import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';

import { SessionService } from './session.service';
import { PusherService } from './pusher.service';

@Injectable()
export class APIService {
	private APIUrl: string = environment.api_url;

	/**
	 * Constructs class and injects dependencies.
	 *
	 * @param {PusherService} pusher_service
	 * @param {Http} httpClient
	 */
	constructor(
		private pusher_service: PusherService,
		private httpClient: HttpClient,
		private session_service: SessionService
	) {}

	/**
	 * Generic request that can be called by sending a method in.
	 *
	 * @param {string} request_type
	 * @param {string} endpoint
	 * @param {HttpParams} http_params
	 * @param {HttpHeaders} http_headers
	 * @returns {Observable}
	 */
	request(
		request_type: string,
		endpoint: string,
		http_params: HttpParams = new HttpParams(),
		http_headers: HttpHeaders = new HttpHeaders(),
		response_type: string = '',
	): Observable<any> {
		return this.httpClient[request_type](this.APIUrl + endpoint, this.buildOptions(http_headers, http_params, response_type));
	}

	/**
	 * Used for post or put requests (has body parameter).
	 *
	 * @param {string} request_type
	 * @param {string} endpoint
	 * @param body
	 * @param {HttpParams} http_params
	 * @param {HttpHeaders} http_headers
	 *
	 * @returns {Observable}
	 */
	postOrPutRequest(
		request_type: string,
		endpoint: string,
		body: any = {},
		http_params: HttpParams = new HttpParams(),
		http_headers: HttpHeaders = new HttpHeaders()
	): Observable<any> {
		return this.httpClient[request_type](
			this.APIUrl + endpoint,
			body,
			this.buildOptions(http_headers, http_params)
		);
	}

	/**
	 * Builds options including headers and search parameters.
	 *
	 * @param {HttpHeaders} http_headers
	 * @param {HttpParams} http_params
	 *
	 * @returns {RequestOptions}
	 */
	private buildOptions(http_headers: HttpHeaders, http_params: HttpParams, response_type: string = ''): any {
		http_headers = http_headers.append('Domain', this.session_service.domain);

		if (this.pusher_service.socketId()) {
			http_headers = http_headers.append('X-Socket-ID', this.pusher_service.socketId());
		}

		return {
			observe: 'response',
			headers: http_headers,
			params: http_params,
			responseType: response_type,
		};
	}
}
