import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { SessionService } from '@app/core/services/session.service';
import { HelperService } from '@app/core/services/helper.service';
import { environment } from '@environments/environment';

import { DomainHelper } from './core/helpers/domain.helper';
import { Dealer } from './core/models/dealer.model';

@Component({
	selector: 'cng-app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.less'],
})
export class AppComponent implements OnInit, OnDestroy {
	modal_is_open: boolean = false;
	app_loading: boolean = false;
	domain: string = DomainHelper.getDomain();
	dealer: Dealer = this.session_service.getDealer();

	destroyed$: Subject<boolean> = new Subject<boolean>();

	/**
	 * Constructs class and injects dependencies.
	 *
	 * @param {Router} router
	 * @param {SessionService} session_service
	 * @param {HelperService} helper_service
	 */
	constructor(
		private router: Router,
		private session_service: SessionService,
		private helper_service: HelperService
	) {
		this.session_service.dealer$.pipe(takeUntil(this.destroyed$)).subscribe((dealer: Dealer) => {
			if (!this.dealer.id) {
				this.dealer = dealer;
				this.initDealerGoogleTagManager();
			}
		});
	}

	/**
	 * Lifecycle hook: Subscribes to router to handle scroll after navigation ends. Also subscribes to app loading state.
	 */
	ngOnInit(): void {
		if (!this.domain && environment.website_domain) {
			(<any>window).location.href = environment.website_domain;
		}
		this.initGoogleTagManager(
			environment.gtm_container_id,
			environment.gtm_environment_id,
			environment.gtm_environment_auth
		);
		this.initDealerGoogleTagManager();

		this.setFavicon();

		this.router.events.pipe(takeUntil(this.destroyed$)).subscribe((routerEvent: RouterEvent) => {
			if (routerEvent instanceof NavigationEnd) {
				const event_opened_modal: boolean = routerEvent.urlAfterRedirects.indexOf('(modal:') !== -1;

				if (!event_opened_modal) {
					if (this.modal_is_open) {
						this.modal_is_open = false;
					} else {
						this.helper_service.scrollTop();
					}
				} else {
					this.modal_is_open = true;
				}
			}
		});

		this.session_service.app_loading$.pipe(takeUntil(this.destroyed$)).subscribe((app_loading: boolean) => {
			// meh
			setTimeout(() => {
				this.app_loading = app_loading;
			});
		});
	}

	/**
	 * Initializes tag manager script
	 */
	initGoogleTagManager(container_id: string, environment_id?: string | null, environment_auth?: string | null): void {
		(<any>window).initGoogleTagManager(
			window,
			document,
			'script',
			'dataLayer',
			container_id,
			environment_id,
			environment_auth
		);
	}

	/**
	 * Initializes dealer owned tag manager script
	 */
	initDealerGoogleTagManager() {
		if (this.dealer.google_tag_manager_id) {
			this.initGoogleTagManager(this.dealer.google_tag_manager_id);
		}
	}

	/**
	 * Sets favicon based on standalone vs dealer domain.
	 */
	setFavicon(): void {
		const link: HTMLLinkElement = document.createElement('link');
		link.type = 'image/x-icon';
		link.rel = 'shortcut icon';
		if (!DomainHelper.isRunningStandalone()) {
			link.href = `https://${this.domain}/img/theme/favicon.png`;
		} else {
			link.href = `${environment.deploy_protocol}${environment.deploy_domain}/assets/img/favicon_orange.png`;
		}
		document.getElementsByTagName('head')[0].appendChild(link);
	}

	/**
	 * Destroys component and any open subscriptions.
	 */
	ngOnDestroy(): void {
		this.destroyed$.next(true);
	}
}
