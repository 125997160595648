<!-- TOASTS  -->

<div class="toasts">
	<ul [class.has-toasts]="toasts.length > 0">

		<!--  TOAST LOOP  -->

		<li *ngFor="let t of toasts" [class.show]="t.show" [ngClass]="t.type">

			<!--  ICON  -->

			<i *ngIf="t.icon !== ''" class="toast-icon" [ngClass]="t.icon"></i>

			<!--  END ICON  -->

			<!--  MESSAGE  -->

			<p>{{ t.message }}</p>

			<!--  END MESSAGE  -->

			<!--  CLOSE BUTTON -->

			<i class="icon-cross-circle" (click)="remove(t)"></i>

			<!--  END CLOSE BUTTON  -->

		</li>

		<!-- END TOAST LOOP  -->

	</ul>
</div>

<!--  TOASTS  -->
