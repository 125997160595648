import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ModalService } from '@app/core/services/modal.service';
import { SessionService } from '@app/core/services/session.service';

import { Dealer } from '@app/core/models/dealer.model';

@Component({
	selector: 'ui-contact-list',
	templateUrl: './contact-list.component.html',
	styleUrls: ['./contact-list.component.less'],
})
export class UIContactListComponent {
	@Input() allow_callback: boolean = false;
	@Output() show_map = new EventEmitter();
	@Output() show_callback = new EventEmitter();

	dealer: Dealer = this.session_service.getDealer();

	constructor(private session_service: SessionService, private modal_service: ModalService) {}

	setMapModalShow(): void {
		this.modal_service.show(['dealer/map']);
	}

	setCallbackModalShow(): void {
		this.modal_service.show(['configure/callback']);
	}
}
