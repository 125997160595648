import { PageScrollConfig } from 'ngx-page-scroll-core';

export const PAGE_SCROLL_CONFIG: PageScrollConfig = {
	duration: 300,
	scrollOffset: 0
};

export function easingLogic(t: number, b: number, c: number, d: number): number {
	// easeInOutExpo easing
	if (t === 0) {
		return b;
	}

	if (t === d) {
		return b + c;
	}

	// tslint:disable-next-line:no-conditional-assignment
	if ((t /= d / 2) < 1) {
		return (c / 2) * Math.pow(2, 10 * (t - 1)) + b;
	}

	return (c / 2) * (-Math.pow(2, -10 * --t) + 2) + b;
}
