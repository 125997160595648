import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { Toast } from '@app/core/models/toast.model';

@Injectable()
export class ToastService {

	toasts$: Subject<Toast[]> = new Subject<Toast[]>();
	toasts: Toast[] = [];

	/**
	 * Creates and shows a success toast.
	 *
	 * @param {string} message
	 */
	success(message: string): void {
		this.add(new Toast({
			message: message,
			type: 'success',
			icon: 'icon-tick',
			duration: 5000
		}));
	}

	/**
	 * Creates and shows an error toast.
	 *
	 * @param {string} message
	 */
	error(message: string): void {
		this.add(new Toast({
			message: message,
			type: 'error',
			icon: 'icon-warning',
			duration: 5000
		}));
	}

	/**
	 * Creates and shows a warning toast.
	 *
	 * @param {string} message
	 */
	warning(message: string): void {
		this.add(new Toast({
			message: message,
			type: 'warning',
			icon: 'icon-warning',
			duration: 5000
		}));
	}

	/**
	 * Creates and shows an info toast.
	 *
	 * @param {string} message
	 */
	info(message: string): void {
		this.add(new Toast({
			message: message,
			type: 'info',
			icon: 'icon-info',
			duration: 5000
		}));
	}

	/**
	 * Adds toast to toasts array.
	 *
	 * @param {Toast} toast
	 */
	add(toast: Toast): void {

		toast.show = false;

		this.toasts.push(toast);
		this.toasts$.next(this.toasts);

		setTimeout(() => {
			if (this.toasts[this.toasts.indexOf(toast)]) {
				this.toasts[this.toasts.indexOf(toast)].show = true;
				this.toasts$.next(this.toasts);
			}
		}, 5);

		setTimeout(() => {
			if (this.toasts[this.toasts.indexOf(toast)]) {
				this.toasts[this.toasts.indexOf(toast)].show = false;
				this.toasts$.next(this.toasts);
			}

		}, toast.duration);

		setTimeout(() => {
			if (this.toasts[this.toasts.indexOf(toast)]) {
				this.toasts.splice(this.toasts.indexOf(toast), 1);
				this.toasts$.next(this.toasts);
			}
		}, toast.duration + 200);
	}

	/**
	 * Removes toast from toasts array.
	 *
	 * @param toast
	 */
	remove(toast: Toast): void {
		if (this.toasts[this.toasts.indexOf(toast)]) {
			this.toasts[this.toasts.indexOf(toast)].show = false;
			this.toasts$.next(this.toasts);

			setTimeout(() => {
				this.toasts.splice(this.toasts.indexOf(toast), 1);
				this.toasts$.next(this.toasts);
			}, 200);
		}
	}

}
