import * as CookieHelper from './cookie.helper';

import { environment } from '@environments/environment';

export class DomainHelper {
	/**
	 * Gets thew domain that the app is running on.
	 */
	static getDomain(): string {
		let domain: string = window.location.host;

		if (
			domain.indexOf(environment.deploy_domain) === -1 &&
			domain !== 'dev.clickmotors.co.uk' &&
			(environment.name === 'develop' || environment.name === 'staging')
		) {
			domain = CookieHelper.getCookie('url');
		}

		return domain;
	}

	/**
	 * Checks if app is running in standalone mode or on dealer's domain
	 */
	static isRunningStandalone() {
		const domain: string = DomainHelper.getDomain();
		return domain && domain.indexOf(environment.deploy_domain) !== -1;
	}

	/**
	 * Returns baseHref depending on where the app is running. / for standalone, /engage/ for dealer website.
	 */
	static getBaseHref(): string {
		return DomainHelper.isRunningStandalone() ? '/' : '/engage/';
	}
}
