import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { UITooltipModule } from '../../directives/tooltip/tooltip.module';
import { UIPopoverModule } from '../popover/popover.module';

import { UIPopoverWithMenuComponent } from './popover-with-menu.component';

@NgModule({
	declarations: [UIPopoverWithMenuComponent],
	imports: [CommonModule, UIPopoverModule, UITooltipModule],
	exports: [UIPopoverWithMenuComponent],
})
export class UIPopoverWithMenuModule {}
