import { Title } from '@angular/platform-browser';
import { Subject, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

import { StorageService } from './storage.service';

import { FinanceParams } from '@app/configure/models/finance-params.model';
import { DealerBranding } from '@app/core/models/dealer-branding.model';
import { Dealer } from '@app/core/models/dealer.model';

import { environment } from '@environments/environment';

import { DomainHelper } from '../helpers/domain.helper';

@Injectable()
export class SessionService {
	dealer$: Subject<Dealer> = new Subject<Dealer>();
	dealer: Dealer;

	app_loading$: Subject<boolean> = new Subject<boolean>();
	app_loading: boolean = true;

	domain: string = DomainHelper.getDomain();

	finance_params$: Subject<FinanceParams> = new Subject<FinanceParams>();
	finance_params: FinanceParams;

	/**
	 * Constructs component and injects dependencies.
	 *
	 * @param {Title} title
	 */
	constructor(private title: Title) {}

	/**
	 * Sets dealer value from param.
	 *
	 * @param {Dealer} dealer
	 */
	setDealer(dealer: Dealer): void {
		this.dealer = dealer;
		this.dealer$.next(this.dealer);
		this.setThemeColors();
	}

	/**
	 * Returns session dealer or placeholder dealer with default branding.
	 *
	 * @returns {Dealer}
	 */
	getDealer(): Dealer {
		if (!this.dealer) {
			return new Dealer({
				branding: new DealerBranding({}),
			});
		}

		return this.dealer;
	}

	/**
	 * Sets finance paras value.
	 */
	setFinanceParams(finance_params: FinanceParams): void {
		this.finance_params = finance_params;
		this.finance_params$.next(this.finance_params);
	}

	/**
	 * Returns session finance params.
	 */
	getFinanceParams(): FinanceParams {
		return this.finance_params;
	}

	/**
	 * Triggers app_loading event to be broadcast across application.
	 *
	 */
	stopAppLoading(): void {
		this.app_loading = false;
		this.app_loading$.next(false);
	}

	/**
	 * Sets <title> tag.
	 *
	 * @param {string} title
	 */
	setTitle(title: string): void {
		this.title.setTitle(title + (this.getDealer().id ? ' | ' + this.getDealer().name : ''));
	}

	/**
	 * Sets CSS variables for dealer branding
	 */
	setThemeColors(): void {
		if (!this.dealer) {
			return;
		}

		const branding_colors: { short: string; long: string }[] = [
			{ short: 'pri-bg', long: 'primary_background' },
			{ short: 'pri-fg', long: 'primary_foreground' },
			{ short: 'sec-bg', long: 'secondary_background' },
			{ short: 'sec-fg', long: 'secondary_foreground' },
			{ short: 'head-bg', long: 'header_background' },
			{ short: 'head-fg', long: 'header_foreground' },
			{ short: 'text-hl', long: 'text_highlight' },
			{ short: 'pos-bg', long: 'positive_background' },
			{ short: 'pos-fg', long: 'positive_foreground' },
			{ short: 'warn-bg', long: 'warning_background' },
			{ short: 'warn-fg', long: 'warning_foreground' },
			{ short: 'neg-bg', long: 'negative_background' },
			{ short: 'neg-fg', long: 'negative_foreground' },
		];

		branding_colors.forEach((branding_color: { short: string; long: string }) => {
			this.setCssVariable(branding_color);
		});
	}

	setCssVariable(branding_color: { short: string; long: string }): void {
		document.documentElement.style.setProperty(
			`--${branding_color.short}`,
			this.dealer.branding[branding_color.long]
		);
		document.documentElement.style.setProperty(
			`--${branding_color.short}-rgb`,
			this.rgbToRGBA(this.dealer.branding[branding_color.long])
		);
	}

	rgbToRGBA(rgb_color: string): string {
		return rgb_color.split('(').pop().split(')')[0];
	}
}
