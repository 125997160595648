import { Component } from '@angular/core';

import { ModalService } from '@app/core/services/modal.service';

import { Modal } from '@app/core/models/modal.model';

@Component({
	selector: 'cng-confirm-modal',
	templateUrl: './confirm.component.html',
})
export class ConfirmModalComponent {
	public config: Modal;

	/**
	 * Constructs component and injects dependencies.
	 *
	 * @param {ModalService} modal_service
	 */
	constructor(private modal_service: ModalService) {
		this.config = this.modal_service.config;
	}
}
