export class StructuredEvent {
	name: string;
	category: string;
	action: string;
	label?: string;
	value?: number;

	constructor(name: string, category: string, action: string, label?: string, value?: number) {
		this.name = name;
		this.category = category;
		this.action = action;
		this.label = label || null;
		this.value = value || null;
	}
}
