import { FinanceQuote } from './../../core/models/finance-quote.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { APIUtilityService } from '@app/core/services/api-utility.service';
import { APIService } from '@app/core/services/api.service';

@Injectable()
export class FinanceManagerAPIService {
	constructor(private api_service: APIService, private api_utility_service: APIUtilityService) {}

	getData(enquiry_hash: string): Observable<any> {
		return this.api_utility_service.intercept(
			this.api_service.request(
				'get',
				'self-service/' + enquiry_hash,
				null,
				this.api_utility_service.getEnquiryHeaders(enquiry_hash)
			),
			enquiry_hash,
			['/finance-manager', enquiry_hash, 'authenticate']
		);
	}

	getEditAuthToken(enquiry_hash: string, enq_finance_quotes_id: number): Observable<any> {
		return this.api_utility_service.intercept(
			this.api_service.request(
				'get',
				['self-service', enquiry_hash, 'proposals', enq_finance_quotes_id, 'auth-token'].join('/'),
				null,
				this.api_utility_service.getEnquiryHeaders(enquiry_hash)
			),
			enquiry_hash,
			['/finance-manager', enquiry_hash, 'authenticate']
		);
	}

	getEsignUrl(enquiry_hash: string, enq_finance_quotes_id: number): Observable<any> {
		return this.api_utility_service.intercept(
			this.api_service.request(
				'get',
				['self-service', enquiry_hash, 'proposals', enq_finance_quotes_id, 'esign'].join('/'),
				null,
				this.api_utility_service.getEnquiryHeaders(enquiry_hash)
			),
			enquiry_hash,
			['/finance-manager', enquiry_hash, 'authenticate']
		);
	}

	cancelApplication(enquiry_hash: string, enq_finance_quotes_id: number, ntu_status: string): Observable<any> {
		return this.api_utility_service.intercept(
			this.api_service.postOrPutRequest(
				'post',
				['self-service', enquiry_hash, 'proposals', enq_finance_quotes_id, 'not-taken-up'].join('/'),
				ntu_status ? { ntu_status } : null,
				null,
				this.api_utility_service.getEnquiryHeaders(enquiry_hash)
			),
			enquiry_hash,
			['/finance-manager', enquiry_hash, 'authenticate']
		);
	}

	recalculate(
		enquiry_hash: string,
		enq_finance_quotes_id: number,
		body: { annual_mileage: number; cash_deposit: number; term: number }
	): Observable<any> {
		return this.api_utility_service.intercept(
			this.api_service.postOrPutRequest(
				'post',
				['self-service', enquiry_hash, 'quotes', enq_finance_quotes_id, 'recalculate'].join('/'),
				body,
				null,
				this.api_utility_service.getEnquiryHeaders(enquiry_hash)
			),
			enquiry_hash,
			['/finance-manager', enquiry_hash, 'authenticate']
		);
	}

	acceptRecalculation(
		enquiry_hash: string,
		enq_finance_quotes_id: number,
		body: { quote: FinanceQuote }
	): Observable<any> {
		return this.api_utility_service.intercept(
			this.api_service.postOrPutRequest(
				'post',
				['self-service', enquiry_hash, 'proposals', enq_finance_quotes_id, 'accept-recalculation'].join('/'),
				body,
				null,
				this.api_utility_service.getEnquiryHeaders(enquiry_hash)
			),
			enquiry_hash,
			['/finance-manager', enquiry_hash, 'authenticate']
		);
	}

	markRecalculationAsSubmitted(enquiry_hash: string, enq_finance_quotes_id: number): Observable<any> {
		return this.api_utility_service.intercept(
			this.api_service.postOrPutRequest(
				'post',
				[
					'self-service',
					enquiry_hash,
					'proposals',
					enq_finance_quotes_id,
					'mark-recalculation-as-submitted',
				].join('/'),
				null,
				null,
				this.api_utility_service.getEnquiryHeaders(enquiry_hash)
			),
			enquiry_hash,
			['/finance-manager', enquiry_hash, 'authenticate']
		);
	}

	uploadDocument(enquiry_hash: string, enq_finance_quotes_id: number, form_data: FormData): Observable<any> {
		return this.api_utility_service.intercept(
			this.api_service.postOrPutRequest(
				'post',
				['self-service', enquiry_hash, 'proposals', enq_finance_quotes_id, 'submit-proofs'].join('/'),
				form_data,
				null,
				this.api_utility_service.getEnquiryHeaders(enquiry_hash)
			),
			enquiry_hash,
			['/finance-manager', enquiry_hash, 'authenticate']
		);
	}

	getDeclinedDoc(enquiry_hash: string, enq_finance_quotes_id: number): Observable<any> {
		return this.api_utility_service.intercept(
			this.api_service.request(
				'get',
				['self-service', enquiry_hash, 'proposals', enq_finance_quotes_id, 'declined'].join('/'),
				null,
				this.api_utility_service.getEnquiryHeaders(enquiry_hash)
			),
			enquiry_hash,
			['/finance-manager', enquiry_hash, 'authenticate']
		);
	}

	getUnsignedDoc(enquiry_hash: string, enq_finance_quotes_id: number, document_id: number): Observable<any> {
		return this.api_utility_service.intercept(
			this.api_service.request(
				'get',
				['self-service', enquiry_hash, 'proposals', enq_finance_quotes_id, 'document', document_id].join('/'),
				null,
				this.api_utility_service.getEnquiryHeaders(enquiry_hash)
			),
			enquiry_hash,
			['/finance-manager', enquiry_hash, 'authenticate']
		);
	}

	sendCommunicationReply(enquiry_hash: string, thread_id: number, form_data: FormData): Observable<any> {
		return this.api_utility_service.intercept(
			this.api_service.postOrPutRequest(
				'post',
				['self-service', enquiry_hash, 'communications', 'reply'].join('/'),
				form_data,
				null,
				this.api_utility_service.getEnquiryHeaders(enquiry_hash)
			),
			enquiry_hash,
			['/finance-manager', enquiry_hash, 'authenticate']
		);
	}

	downloadFile(enquiry_hash: string, thread_id: number, message_id: number, attachment_id: number): Observable<any> {
		return this.api_utility_service.intercept(
			this.api_service.request(
				'get',
				['self-service', enquiry_hash, 'communications', thread_id, message_id, 'download', attachment_id].join('/'),
				null,
				this.api_utility_service.getEnquiryHeaders(enquiry_hash),
				'blob'
			),
			enquiry_hash,
			['/finance-manager', enquiry_hash, 'authenticate']
		);
	}

	markCommunicationRead(enquiry_hash: string, thread_id: number): Observable<any> {
		return this.api_utility_service.intercept(
			this.api_service.postOrPutRequest(
				'put',
				['self-service', enquiry_hash, 'communications', 'read'].join('/'),
				{
					thread_id: thread_id
				},
				null,
				this.api_utility_service.getEnquiryHeaders(enquiry_hash)
			),
			enquiry_hash,
			['/finance-manager', enquiry_hash, 'authenticate']
		);
	}
}
