import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { UIPopoverWithMenuModule } from '../popover-with-menu/popover-with-menu.module';
import { UITooltipModule } from '../../directives/tooltip/tooltip.module';

import { UIDealerHeaderComponent } from './dealer-header.component';

@NgModule({
	declarations: [UIDealerHeaderComponent],
	imports: [CommonModule, UIPopoverWithMenuModule, UITooltipModule],
	exports: [UIDealerHeaderComponent],
})
export class UIDealerHeaderModule {}
