import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

import { AppError } from '@app/core/models/app-error.model';

@Injectable()
export class ErrorService {
	private error_source: Subject<AppError> = new Subject<AppError>();
	error$: Observable<AppError> = this.error_source.asObservable();

	private error_codes: { [key: number]: any } = {
		401: {
			status_code: 401,
			title: 'Login Required',
			log: 'request',
			icon: 'icon-padlock',
		},
		403: {
			status_code: 403,
			title: 'Unauthorized',
			log: 'request',
			icon: 'icon-padlock',
		},
		404: {
			status_code: 404,
			title: 'Not Found',
			log: 'request',
			icon: 'icon-carpark',
		},
		410: {
			status_code: 410,
			title: 'Vehicle not available',
			log: 'never',
			icon: 'icon-no-car',
		},
		500: {
			status_code: 500,
			title: 'Service Problem',
			log: 'never',
			icon: 'icon-breakdown',
		},
		503: {
			status_code: 503,
			title: 'Maintenance',
			log: 'never',
			icon: 'icon-cog-spanner',
		},
	};

	private unknown_error: any = {
		status_code: null,
		title: 'Unknown Error',
		log: 'always',
		icon: 'icon-breakdown',
	};

	/**
	 * Shows error based on its error code. Logs error if log is set to 'always'.
	 *
	 * @param {number} status_code
	 * @param details
	 * @param {string} code_block
	 * @param error
	 */
	show(status_code: number, details: any, code_block: string, error: any = null): void {
		const error_meta: any = this.error_codes[status_code] || this.unknown_error;

		const current_error: AppError = {
			status_code: error_meta.status_code || null,
			title: error_meta.title,
			log: error_meta.log,
			icon: error_meta.icon,
			details: details || null,
			code_block: code_block || null,
		};

		this.error_source.next(current_error);

		if (current_error.log === 'always' || error !== null) {
			this.logError(current_error, error);
		}
	}

	/**
	 * Logs error to error tracking.
	 *
	 * @param {AppError} appError
	 * @param {Error} error
	 */
	logError(appError: AppError, error: Error = null): void {
		console.log(appError || error);
		throw error;
	}
}
