<ul id="contact-box" class="key-value-list with-icons contact-list">
	<li *ngIf="allow_callback">
		<i class="icon-callback text-hl"></i>
		<div class="key callback-key-list">
			<h3>Arrange callback</h3>
			<p>We'll call you at a convenient time</p>
		</div>
		<div class="value">
			<a class="button small-button" (click)="setCallbackModalShow()">Arrange Callback</a>
		</div>
	</li>
	<li>
		<i class="icon-telephone text-hl"></i>
		<div class="key">Call us</div>
		<div class="value">
			<a [attr.href]="'tel:' + dealer.telephone" class="text-hl">
				{{ dealer.telephone }}
			</a>
		</div>
	</li>
	<li *ngIf="dealer.email !== null">
		<i class="icon-email text-hl"></i>
		<div class="key">Email us</div>
		<div
			class="value"
			[ngClass]="{ 'small-text': dealer.email.length > 27, 'smaller-text': dealer.email.length > 34 }"
		>
			<a [attr.href]="'mailto:' + dealer.email" class="lowercase text-hl">
				{{ dealer.email }}
			</a>
		</div>
	</li>
	<li>
		<i class="icon-pinpoint text-hl"></i>
		<div class="key">
			<div>Visit us</div>
			<p [innerHtml]="dealer.address"></p>
		</div>
	</li>
</ul>
