import { ErrorHandler, Injectable, Injector } from '@angular/core';

import { ErrorService } from '@app/core/services/error.service';

@Injectable()
export class AppErrorHandler implements ErrorHandler {

	/**
	 * Constructs class and injects dependencies.
	 *
	 * @param {Injector} injector
	 */
	constructor(
		private injector: Injector
	) {
	}

	/**
	 * Catches and handles application errors.
	 *
	 * @param error
	 */
	handleError(error: any): void {
		const chunk_failed_message: RegExp = /Loading chunk [\d]+ failed/;

        if (error.message && chunk_failed_message.test(error.message)) {
            window.location.reload();
            return;
		}

		const error_service = this.injector.get(ErrorService);
		error_service.show(null, error.stack || error.message || error.toString() || ':/', 'app/classes/error-handler :: handleError(' + (error || 'empty') + ')', error);
	}


}
