import { ActivatedRoute, Params, Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { StorageService } from '@app/core/services/storage.service';
import { ErrorService } from '@app/core/services/error.service';

@Component({
	selector: 'cng-start',
	templateUrl: './start.component.html'
})

export class StartComponent implements OnInit, OnDestroy {

	private destroyed$: Subject<boolean> = new Subject<boolean>();

	/**
	 * Constructs component and its dependencies.
	 *
	 * @param {ActivatedRoute} activated_route
	 * @param {StorageService} storage_service
	 * @param {ErrorService} error_service
	 * @param {Router} router
	 */
	constructor(
		private activated_route: ActivatedRoute,
		private storage_service: StorageService,
		private error_service: ErrorService,
		private router: Router
	) {
	}

	/**
	 * Lifecycle hook: Subscribes to query params.
	 */
	ngOnInit(): void {

		const query_params: Params = this.activated_route.snapshot.queryParams;

		this.activated_route.params.pipe(takeUntil(this.destroyed$)).subscribe((params: Params) => {

			if (typeof(params['token']) !== 'undefined' && typeof(query_params['redirect']) !== 'undefined') {

				switch (query_params['redirect']) {

					case 'sale-presentation':

						this.storage_service.setItem('token', params['token'], 7);
						this.router.navigate(['/sale-presentation', query_params['enquiry_id']]);

						break;

					case 'configure':

						this.storage_service.removeAllThatStartWith('token-');
						this.storage_service.removeAllThatStartWith('vehicle-');
						this.storage_service.setItem('token-' + query_params['enquiry_hash'], params['token'], 1);
						this.storage_service.setItem('dnt', 'true', 1);
						this.router.navigate(['/configure', 'e', query_params['enquiry_hash']]);

						break;

				}

			} else {
				this.fail();
			}

		});

	}

	/**
	 * Shows error to user
	 */
	private fail(): void {
		this.error_service.show(404, {message: 'invalid or empty token'}, 'app/core/components/start/start.component :: fail()');
	}

	/**
	 * Lifecycle hook: Destroys component and any open subscriptions.
	 */
	ngOnDestroy(): void {
		this.destroyed$.next(true);
	}

}

