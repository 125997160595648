import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { FinanceManagerAPIService } from './finance-manager-api.service';
import { CoreAPIService } from '@app/core/services/core-api.service';
import { StorageService } from '@app/core/services/storage.service';
import { HelperService } from '@app/core/services/helper.service';

import { environment } from '@environments/environment';

import { FMAction } from '../interfaces/action.interface';
import { FinanceQuote } from '@app/core/models/finance-quote.model';
import { HttpResponse } from '@angular/common/http';

@Injectable()
export class FinanceManagerService {
	enquiry$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

	constructor(
		private core_api_service: CoreAPIService,
		private finance_manager_api_service: FinanceManagerAPIService,
		private helper_service: HelperService,
		private storage_service: StorageService
	) {}

	getEnquiry(enquiry_hash: string): Observable<any> {
		return this.finance_manager_api_service.getData(enquiry_hash).pipe(
			map((response) => {
				return this.setEnquiry(response.body.enquiry, true);
			})
		);
	}

	setEnquiry(enquiry, first_run: boolean = false): any {
		if (first_run) {
			if (enquiry.purchase_vehicle && enquiry.purchase_vehicle.make) {
				enquiry.purchase_vehicle.brand_logo_url = this.helper_service.buildManufacturerBrandLogoUrl(
					enquiry.purchase_vehicle.make
				);
			}

			if (enquiry.purchase_vehicle) {
				enquiry.purchase_vehicle.images = enquiry.purchase_vehicle.images.map((image_path: string) => {
					return environment.vehicle_image_url + image_path;
				});
			}

			if (enquiry.part_exchange.length) {
				enquiry.part_exchange = enquiry.part_exchange.map((part_exchange) => {
					part_exchange.vehicle.brand_logo_url = this.helper_service.buildManufacturerBrandLogoUrl(
						part_exchange.vehicle.make
					);
					part_exchange.vehicle.price = part_exchange.vehicle.allowance;
					return part_exchange;
				});
			}

			enquiry.addons.map((addon) => {
				if (addon.provider_logo) {
					addon.provider_logo = this.helper_service.buildAddonBrandLogoUrl(addon.provider_logo);
				}
				return addon;
			});
		}

		enquiry.finance.non_pending_documents = FinanceManagerService.getNonPendingDocuments(enquiry);
		enquiry.finance.declined_documents = FinanceManagerService.getDeclinedDocuments(enquiry);
		enquiry.finance.unsigned_documents = FinanceManagerService.getUnsignedDocuments(enquiry);

		enquiry.finance.pending_actions = this.getPendingActions(enquiry);

		enquiry.finance.status = this.getFinanceStatus(enquiry);
		enquiry.finance.friendly_status = this.helper_service.convertCase(enquiry.finance.status, 'pascal', 'title');
		enquiry.finance.status_icon = this.helper_service.getFinanceStatusIcon(enquiry.finance.status);

		this.enquiry$.next(enquiry);
		return enquiry;
	}

	private getFinanceStatus(enquiry): string {
		if (!enquiry.finance.proposal.is_eligible && enquiry.finance.proposed_quote) {
			return 'NotEligible';
		}

		if (!enquiry.finance.proposed_quote) {
			return 'Inactive';
		}

		return this.helper_service.getFinanceStatus(enquiry.finance.proposed_quote.status);
	}

	private getPendingActions(enquiry: any): FMAction[] {
		if (!enquiry.finance.proposed_quote || !enquiry.finance.proposal.is_eligible) {
			return [];
		}

		let blocked_esign_message: string = null;
		const actions: FMAction[] = enquiry.finance.proposal.actions.filter((action) => {
			return action.status === 'PENDING';
		});

		if (enquiry.finance.proposal.resubmission_required) {
			actions.unshift({
				type: 'resubmission',
				name: 'Resubmission Required',
				description: 'You need to resubmit your application to confirm the changes you made.',
				status: 'PENDING',
			});

			blocked_esign_message =
				'Before you sign your finance agreement, you need to resubmit your application. Once this has been done, we’ll display the esign option for you here.';
		}

		if (enquiry.finance.proposal.recalculaton_required) {
			actions.unshift({
				type: 'recalculation',
				name: 'Recalculation Required',
				description:
					'Something changed that affected your loan details. Please recalculate to get an accurate quote.',
				status: 'PENDING',
			});

			blocked_esign_message =
				'Before you sign your finance agreement, you need to recalculate your loan and resubmit your application. Once this has been done, we’ll display the esign option for you here.';
		}

		if (
			enquiry.part_exchange.length &&
			!enquiry.part_exchange[0].vehicle.allowance_approved &&
			enquiry.finance.proposed_quote.esign_available
		) {
			blocked_esign_message =
				'Before you can sign your finance agreement, the dealership needs to verify your part exchange information. Once this has been done, we’ll display the esign option for you here.';
		}

		if (enquiry.finance.proposed_quote.esign_available && blocked_esign_message) {
			actions.push({
				type: 'e-sign',
				name: 'Agreement E-Signature',
				description: blocked_esign_message,
				status: 'BLOCKED',
			});
		}

		if (enquiry.finance.unsigned_documents && enquiry.finance.unsigned_documents.length) {
			actions.push({
				type: 'document',
				name: 'Documents Available',
				description: 'Your unsigned documents are now available. Please check your documents area and contact the dealership to have them returned.',
				status: 'PENDING',
			});
		}

		return actions;
	}

	private static getNonPendingDocuments(enquiry): FMAction[] {
		if (!enquiry.finance.proposed_quote) {
			return [];
		}

		return enquiry.finance.proposal.actions.filter((action) => {
			return action.type === 'proof' && action.status !== 'PENDING';
		});
	}

	private static getDeclinedDocuments(enquiry): FMAction[] {
		if (!enquiry.finance.proposed_quote) {
			return [];
		}

		return enquiry.finance.proposal.actions.filter((action) => {
			return action.type === 'declined';
		});
	}

	private static getUnsignedDocuments(enquiry): FMAction[] {
		if (!enquiry.finance.proposed_quote) {
			return [];
		}

		return enquiry.finance.proposal.actions.filter((action) => {
			return action.type === 'unsigned';
		});
	}

	getEditAuthToken(): Observable<string> {
		return this.finance_manager_api_service
			.getEditAuthToken(
				this.enquiry$.value.hash,
				this.enquiry$.value.finance.proposed_quote.enq_finance_quotes_id
			)
			.pipe(
				map((response) => {
					return response.body.auth_token;
				})
			);
	}

	getEsignUrl(): Observable<any> {
		return this.finance_manager_api_service
			.getEsignUrl(this.enquiry$.value.hash, this.enquiry$.value.finance.proposed_quote.enq_finance_quotes_id)
			.pipe(
				map((response) => {
					return response.body;
				})
			);
	}

	getDeclinedDoc(): Observable<any> {
		return this.finance_manager_api_service
			.getDeclinedDoc(this.enquiry$.value.hash, this.enquiry$.value.finance.proposed_quote.enq_finance_quotes_id)
			.pipe(
				map((response) => {
					return response.body.data;
				})
			);
	}

	getUnsignedDoc(document_id: number): Observable<any> {
		return this.finance_manager_api_service
			.getUnsignedDoc(this.enquiry$.value.hash, this.enquiry$.value.finance.proposed_quote.enq_finance_quotes_id, document_id)
			.pipe(
				map((response) => {
					return response.body.data;
				})
			);
	}

	sendCommunicationReply(thread_id: number, form_data: FormData): Observable<any> {
		return this.finance_manager_api_service
			.sendCommunicationReply(this.enquiry$.value.hash, thread_id, form_data)
			.pipe(
				map((response) => {
					return response.body.data;
				})
			);
	}

	downloadFile(thread_id: number, message_id: number, attachment_id: number, attachment): Observable<any> {
		return this.finance_manager_api_service
			.downloadFile(this.enquiry$.value.hash, thread_id, message_id, attachment_id)
			.pipe(
				map((response: HttpResponse<Blob>) => {
					const file = new window.Blob([response.body, response.headers.get('Content-Type')]);
		
					const downloadAncher = document.createElement("a");
					downloadAncher.style.display = "none";
		
					const fileURL = URL.createObjectURL(file);
					downloadAncher.href = fileURL;
					downloadAncher.download = attachment.document.filename + '.' + attachment.document.extension;
					downloadAncher.click();
				})
			);
	}

	markCommunicationRead(thread_id: number): Observable<any> {
		return this.finance_manager_api_service
			.markCommunicationRead(this.enquiry$.value.hash, thread_id)
			.pipe(
				map((response) => {
					return response.body.data;
				})
			);
	}

	cancelApplication(ntu_status: string = null): Observable<any> {
		return this.finance_manager_api_service
			.cancelApplication(
				this.enquiry$.value.hash,
				this.enquiry$.value.finance.proposed_quote.enq_finance_quotes_id,
				ntu_status
			)
			.pipe(
				map((response) => {
					return response;
				})
			);
	}

	recalculate(body: { annual_mileage: number; cash_deposit: number; term: number }): Observable<any> {
		return this.finance_manager_api_service
			.recalculate(
				this.enquiry$.value.hash,
				this.enquiry$.value.finance.proposed_quote.enq_finance_quotes_id,
				body
			)
			.pipe(
				map((response) => {
					return response.body.quote;
				})
			);
	}

	acceptRecalculation(body: { quote: FinanceQuote }): Observable<any> {
		return this.finance_manager_api_service
			.acceptRecalculation(
				this.enquiry$.value.hash,
				this.enquiry$.value.finance.proposed_quote.enq_finance_quotes_id,
				body
			)
			.pipe(
				map((response) => {
					return response.body;
				})
			);
	}

	markRecalculationAsSubmitted(): Observable<any> {
		return this.finance_manager_api_service.markRecalculationAsSubmitted(
			this.enquiry$.value.hash,
			this.enquiry$.value.finance.proposed_quote.enq_finance_quotes_id
		);
	}

	uploadDocument(form_data: FormData): Observable<any> {
		return this.finance_manager_api_service
			.uploadDocument(
				this.enquiry$.value.hash,
				this.enquiry$.value.finance.proposed_quote.enq_finance_quotes_id,
				form_data
			)
			.pipe((response) => {
				return response;
			});
	}

	track(type: string, data: any = null): Observable<any> {
		if (this.enquiry$.value.hash && this.storage_service.getItem('dnt') !== 'true') {
			const body: any = {
				type: type,
				body: data,
			};

			return this.core_api_service.trackActivity(this.enquiry$.value.hash, body).pipe(
				catchError(() => {
					return of(true);
				})
			);
		}
		return of(true);
	}
}
