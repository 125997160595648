import { RouterModule, Routes } from '@angular/router';

import { DealerContactModalComponent } from './core/modal-components/dealer-contact/dealer-contact.component';
import { DealerMapModalComponent } from './core/modal-components/dealer-map/dealer-map.component';
import { ConfirmModalComponent } from './core/modal-components/confirm/confirm.component';
import { NotFoundComponent } from './core/components/not-found/not-found.component';
import { ModalComponent } from './core/components/modal/modal.component';
import { StartComponent } from './core/components/start/start.component';

import { DealerResolver } from './core/resolvers/dealer.resolver';

const routes: Routes = [
	{
		path: 'start/:token',
		component: StartComponent,
	},
	{
		path: 'sale-presentation',
		loadChildren: () => import('./sale-presentation/sale-presentation.module').then((m) => m.SalePresentationModule),
		resolve: { dealer: DealerResolver },
	},
	{
		path: 'configure',
		loadChildren: () => import('./configure/configure.module').then((m) => m.ConfigureModule),
	},
	{
		path: 'sign',
		loadChildren: () => import('./sign/sign.module').then((m) => m.SignModule),
	},
	{
		path: 'proposal',
		loadChildren: () => import('./proposal/proposal.module').then((m) => m.ProposalModule),
	},
	{
		path: 'soft-credit-checker',
		loadChildren: () => import('./soft-search/soft-search.module').then((m) => m.SoftSearchModule),
	},
	{
		path: 'part-exchange',
		loadChildren: () => import('./part-exchange/part-exchange.module').then((m) => m.PartExchangeModule),
	},
	{
		path: 'library',
		loadChildren: () => import('./library/library.module').then((m) => m.LibraryModule),
		resolve: { data: DealerResolver },
	},
	{
		path: 'finance-manager',
		loadChildren: () => import('./finance-manager/finance-manager.module').then((m) => m.FinanceManagerModule),
		resolve: { data: DealerResolver },
	},
	{
		path: 'not-found',
		component: NotFoundComponent,
	},
	{
		outlet: 'modal',
		path: 'dialog',
		component: ModalComponent,
		children: [
			{
				path: 'dealer/map',
				component: DealerMapModalComponent,
			},
			{
				path: 'dealer/contact',
				component: DealerContactModalComponent,
			},
			{
				path: 'confirm',
				component: ConfirmModalComponent,
			},
			{
				path: 'sale-presentation',
				loadChildren: () => import('./sale-presentation/sale-presentation.module').then((m) => m.SalePresentationModule),
			},
			{
				path: 'configure',
				loadChildren: () => import('./configure/configure.module').then((m) => m.ConfigureModule),
			},
			{
				path: 'finance-manager',
				loadChildren: () => import('./finance-manager/finance-manager.module').then((m) => m.FinanceManagerModule),
			},
			{
				path: 'soft-credit-checker',
				loadChildren: () => import('./soft-search/soft-search.module').then((m) => m.SoftSearchModule),
			},
		],
	},
	{
		path: '**',
		redirectTo: '/not-found',
	},
];

export const AppRouterModule: any = RouterModule.forRoot(routes);
