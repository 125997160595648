import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Component, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { ErrorService } from '@app/core/services/error.service';

@Component({
	selector: 'cng-not-found',
	template: ''
})

export class NotFoundComponent implements OnDestroy {
	
	private destroyed$: Subject<boolean> = new Subject<boolean>();
	
	/**
	 * Constructs component and its dependencies. Subscribes to router events.
	 *
	 * @param {ErrorService} error_service
	 * @param {Router} router
	 */
	constructor(
		private error_service: ErrorService,
		private router: Router
	) {
		
		this.router.events.pipe(takeUntil(this.destroyed$)).subscribe((router_event: RouterEvent) => {
			
			if (router_event instanceof NavigationEnd) {
				this.error_service.show(404, {message: 'invalid route', route: router_event.url},
					'app/core/components/not-found/not-found.component :: constructor()'
				);
			}
			
		});
		
	}
	
	/**
	 * Lifecycle hook: Destroys component and any open subscriptions.
	 */
	ngOnDestroy(): void {
		this.destroyed$.next(true);
	}
	
}
