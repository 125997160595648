import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

interface Script {
	name: string;
	src: string;
}

interface ScriptLoadParameters {
	[key: string]: any;
}

const ScriptStore: Script[] = [
	{
		name: 'swiper',
		src: 'https://cdnjs.cloudflare.com/ajax/libs/Swiper/3.3.1/js/swiper.min.js',
	},
	{
		name: 'stripe',
		src: 'https://js.stripe.com/v3/',
	},
	{
		name: 'paypal',
		src: 'https://www.paypalobjects.com/api/checkout.js',
	},
	{
		name: 'braintree',
		src: 'https://js.braintreegateway.com/web/dropin/1.3.1/js/dropin.min.js',
	},
	{
		name: 'visitorchat',
		src: 'https://cdn.visitor.chat/vc.min.js',
	},
	{
		name: 'codeweavers_proposal_form',
		src: 'https://plugins.codeweavers.net/scripts/v1/platform/proposal-form',
	},
	{
		name: 'codeweavers_ecommerce_form',
		src: 'https://plugins.codeweavers.net/scripts/v1/platform/ecommerce',
	},
	{
		name: 'click_proposal_form',
		src: `${environment.deploy_protocol}${environment.deploy_domain}/assets/js/proposal-form.js`,
	},
	{
		name: 'payment_sense',
		src: 'https://web.e.connect.paymentsense.cloud/assets/js/client.js',
	},
	{
		name: 'payment_sense_sandbox',
		src: 'https://web.e.test.connect.paymentsense.cloud/assets/js/client.js',
	},
];

declare const document: any;

@Injectable()
export class ScriptLoaderService {
	private scripts: any = {};

	/**
	 * Constructs class and injects dependencies.
	 */
	constructor() {
		ScriptStore.forEach((script: Script) => {
			this.scripts[script.name] = {
				loaded: false,
				src: script.src,
			};
		});
	}

	/**
	 * Loads array of scripts
	 *
	 * @param {string} scripts
	 *
	 * @returns {Promise}
	 */
	load(...scripts: { name: string; params?: ScriptLoadParameters }[]): Promise<any> {
		const promises: any[] = [];
		scripts.forEach((script: { name: string; params?: ScriptLoadParameters }) => {
		  promises.push(this.loadScript(script.name, script.params));
		});
		return Promise.all(promises);
	  }

	/**
	 * Loads single script.
	 *
	 * @param {string} name
	 *
	 * @returns {Promise}
	 */
	loadScript(name: string, params?: ScriptLoadParameters): Promise<any> {
		return new Promise((resolve: any): void => {
			if (this.scripts[name].loaded) {
				resolve({ script: name, loaded: true, status: 'Already Loaded' });
			} else {
				const script: any = document.createElement('script');
				script.type = 'text/javascript';
				script.src = this.scripts[name].src;

				if (params) {
					const queryString = new URLSearchParams(params).toString();
					script.src += `?${queryString}`;
				}

				if (script.readyState) {
					// IE

					script.onreadystatechange = (): void => {
						if (script.readyState === 'loaded' || script.readyState === 'complete') {
							script.onreadystatechange = null;
							this.scripts[name].loaded = true;
							resolve({ script: name, loaded: true, status: 'Loaded' });
						}
					};
				} else {
					// Decent browsers

					script.onload = (): void => {
						this.scripts[name].loaded = true;
						resolve({ script: name, loaded: true, status: 'Loaded' });
					};
				}

				script.onerror = (): void => resolve({ script: name, loaded: false, status: 'Loaded' });

				document.getElementsByTagName('head')[0].appendChild(script);
			}
		});
	}
}
