import { Injectable } from '@angular/core';

import { HelperService } from './helper.service';

@Injectable()
export class DataLayerService {
	dataLayer = (<any>window)['dataLayer'];

	constructor(private helper_service: HelperService) {
		this.dataLayer = this.dataLayer || [];
	}

	push(item: any) {
		this.dataLayer.push(item);
	}

	gtag(..._args: any[]) {
		this.dataLayer.push(arguments);
	}

	pushEnquiry(configure_data: any) {
		const data = this.helper_service.copy(configure_data);
		const customer_fields: string[] = ['first_name', 'last_name', 'email', 'tel_mob'];

		customer_fields.forEach((field) => {
			if (data.meta && data.meta.customer[field]) {
				data.meta.customer[field] = 'anonymised';
			}
		});

		this.dataLayer.push({ event: 'enquiryData', enquiryData: data });
	}
}
