export class DealerBranding {
	primary_background?: string = '#134993';
	primary_foreground?: string = '#ffffff';
	primary_highlight?: string = '#f2b521';
	header_background?: string = '#134993';
	header_foreground?: string = '#ffffff';
	secondary_background?: string = '#f2b521';
	secondary_foreground?: string = '#ffffff';
	text_highlight?: string = '#6aa9de';
	logo_on_light?: string = '/assets/img/logo_on_light.png';
	logo_on_primary?: string = '/assets/img/logo_on_primary.png';
	logo_on_header?: string = '/assets/img/logo_on_header.png';
	positive_background?: string = 'rgb(43,159,87)';
	positive_foreground?: string = 'rgb(255,255,255)';
	warning_background?: string = 'rgb(245,127,21)';
	warning_foreground?: string = 'rgb(255,255,255)';
	negative_background?: string = 'rgb(214,53,53)';
	negative_foreground?: string = 'rgb(255,255,255)';
	text_highlight2?: string = 'rgb(33,86,160)';

	constructor(dealerBranding: DealerBranding) {
		Object.assign(this, dealerBranding);
	}
}
