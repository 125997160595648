export function getCookie(key: string): string {
	const name: string = key + '=';
	const decodedCookie: string = decodeURIComponent(document.cookie);
	const ca: string[] = decodedCookie.split(';');
	for (let i: number = 0; i < ca.length; i++) {
		let c: string = ca[i];
		while (c.charAt(0) === ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length);
		}
	}
	return null;
}

export function setCookie(key: string, value: string, expiryDays: number = 7): void {
	const d: Date = new Date();
	d.setTime(d.getTime() + expiryDays * 24 * 60 * 60 * 1000); // days * hours * minutes * seconds * milliseconds
	const expires: string = 'expires=' + d.toUTCString();
	document.cookie = key + '=' + value + ';' + expires + ';path=/';
}

export function getCookiesThatStartWith(key: string): any {
	const cookies: any = {};

	if (document.cookie && document.cookie !== '') {
		const split: string[] = document.cookie.split(';');
		for (let i: number = 0; i < split.length; i++) {
			const name_value: string[] = split[i].split('=');
			name_value[0] = name_value[0].replace(/^ /, '');
			if (name_value[0].indexOf(key) !== -1) {
				cookies[decodeURIComponent(name_value[0])] = decodeURIComponent(name_value[1]);
			}
		}
	}

	return cookies;
}
