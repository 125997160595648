import { Component, OnDestroy, ElementRef, ViewChild, Renderer2, HostListener } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { ModalService } from '../../services/modal.service';

import { ModalAction } from '../../models/modal-action.model';
import { Modal } from '../../models/modal.model';

@Component({
	selector: 'cng-modal',
	templateUrl: './modal.component.html',
	styleUrls: ['./modal.component.less'],
})
export class ModalComponent implements OnDestroy {
	config: Modal;
	destroyed$: Subject<boolean> = new Subject<boolean>();

	@ViewChild('modalFrame', { static: true }) modalFrame: ElementRef;

	constructor(private modal_service: ModalService, private renderer: Renderer2) {
		this.initModal();
	}

	initModal(): void {
		this.config = Object.assign(new Modal(), this.modal_service.config);

		this.modal_service.config$.pipe(takeUntil(this.destroyed$)).subscribe((modalConfig: Modal) => {
			this.config = Object.assign(new Modal(), modalConfig);
		});

		setTimeout(() => {
			this.modal_service.setShow(true);
		});
	}

	@HostListener('window:resize')
	positionFrame(): void {
		// TODO: Come back to this

		const frame: HTMLDivElement = this.modalFrame.nativeElement;
		const computedStyle: any = window.getComputedStyle(frame);
		const frameWidth: number = computedStyle.width.replace('px', '');
		const frameHeight: number = computedStyle.height.replace('px', '');

		this.renderer.setStyle(frame, 'transform', 'none');

		this.renderer.setStyle(frame, 'margin-left', '-' + Math.round(frameWidth / 2) + 'px');
		if (this.config.height !== 'full') {
			this.renderer.setStyle(frame, 'margin-top', '-' + Math.round(frameHeight / 2) + 'px');
		}
	}

	onClose(): void {
		if (this.config.canDismiss) {
			this.modal_service.close();
		}
	}

	onCloseWithOverlay(): void {
		if (this.config.canDismiss && this.config.canDismissWithOverlay) {
			this.modal_service.close();
		}
	}

	onClick(action: ModalAction): void {
		action.loading = action.showLoading;
		action.click(action);
	}

	ngOnDestroy(): void {
		this.destroyed$.next(true);
	}
}
