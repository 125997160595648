import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { UIContactListComponent } from './contact-list.component';

@NgModule({
	declarations: [UIContactListComponent],
	imports: [CommonModule],
	exports: [UIContactListComponent],
})
export class UIContactListModule {}
