import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { ConfigureService } from '@app/configure/services/configure.service';
import { StorageService } from '@app/core/services/storage.service';

import { Resolvers } from '@app/core/resolvers/resolvers';

@Injectable()
export class ConfigureEnquiryResolver implements Resolve<any> {
	/**
	 * Constructs class and injects dependencies.
	 *
	 * @param {Resolvers} resolvers
	 * @param {ConfigureService} configure_service
	 * @param storage_service
	 */
	constructor(
		private resolvers: Resolvers,
		private configure_service: ConfigureService,
		private storage_service: StorageService
	) {}

	/**
	 * Resolves route with enquiry and dealer.
	 *
	 * @param {ActivatedRouteSnapshot} activated_route_snapshot
	 *
	 * @returns {Observable}
	 */
	resolve(activated_route_snapshot: ActivatedRouteSnapshot): Observable<any> {
		const enquiry_hash: string = activated_route_snapshot.params['enquiry_hash'];

		if (Object.keys(activated_route_snapshot.queryParams).length > 0) {
			this.storage_service.setItem('trackingQueryParams', JSON.stringify(activated_route_snapshot.queryParams));
		}

		return forkJoin([
			this.resolvers.getEnquiry(enquiry_hash, this.configure_service.getStepMode()),
			this.resolvers.getDealer(new HttpParams().append('enquiry_hash', enquiry_hash)),
		]);
	}
}
