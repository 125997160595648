import { HttpResponse, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { ConfigureAPIService } from '@app/configure/services/configure-api.service';
import { CoreAPIService } from '@app/core/services/core-api.service';
import { SessionService } from '@app/core/services/session.service';
import { HelperService } from '@app/core/services/helper.service';
import { DataLayerService } from '../services/data-layer.service';
import { ErrorService } from '@app/core/services/error.service';

import { ConfigureStruct } from '@app/configure/structs/configure.struct';

import { Dealer } from '@app/core/models/dealer.model';

@Injectable()
export class Resolvers {
	/**
	 * Constructs class and injects dependencies.
	 *
	 * @param {ConfigureAPIService} configure_api_service
	 * @param {CoreAPIService} core_api_service
	 * @param {SessionService} session_service
	 * @param {HelperService} helper_service
	 * @param {DomSanitizer} dom_sanitizer
	 * @param {ErrorService} error_service
	 * @param {Router} router
	 */
	constructor(
		private configure_api_service: ConfigureAPIService,
		private core_api_service: CoreAPIService,
		private session_service: SessionService,
		private helper_service: HelperService,
		private dom_sanitizer: DomSanitizer,
		private error_service: ErrorService,
		private data_layer_service: DataLayerService
	) {}

	/**
	 * Gets dealer and sets additional info. Will resolve from session service if already set.
	 *
	 * @returns {Observable<Dealer>}
	 */
	getDealer(http_params: HttpParams = new HttpParams()): Observable<Dealer> {
		if (!this.session_service.getDealer().id) {
			return this.core_api_service.getDealer(http_params).pipe(
				map((response: HttpResponse<any>) => {
					switch (response.status) {
						case 200:
							const dealer: Dealer = response.body;
							const lines: Array<string> = [dealer.address1, dealer.address2, dealer.address3, dealer.town, dealer.postcode];
							dealer.address = this.helper_service.buildAddress(lines, false);

							dealer.google_maps_embed = this.dom_sanitizer.bypassSecurityTrustResourceUrl(dealer.map);

							if (dealer.website_url) {
								dealer.website_url = 'http://' + dealer.website_url;
								dealer.domain = dealer.website_url.replace('http://', '').replace('https://', '').split(/[/?#]/)[0];
							}

							if (
								dealer.branding.logo_on_header.indexOf('http://') === -1 &&
								dealer.branding.logo_on_header.indexOf('https://') === -1
							) {
								dealer.branding.logo_on_header = 'https://images.clickdealer.co.uk/logos/' + dealer.branding.logo_on_header;
							}

							dealer.friendly_name = dealer.friendly_name || dealer.name;
							dealer.email = dealer.email ? dealer.email.toLowerCase() : null;

							if (dealer.google_analytics_code) {
								this.data_layer_service.push({ GA_ID: dealer.google_analytics_code });
							}

							if (dealer.ga4MeasurementId) {
								this.data_layer_service.push({ GA4_ID: dealer.ga4MeasurementId });
							}

							this.data_layer_service.push({ event: 'dealerData', dealerData: dealer });
							this.session_service.setDealer(dealer);

							return dealer;

						default:
							this.error_service.show(response.status, response.body, 'app/core/resolvers/resolvers :: getDealer()');
							return null;
					}
				})
			);
		} else {
			return of(this.session_service.getDealer());
		}
	}

	/**
	 * Gets vehicle and sets additional info.
	 *
	 * @param {number} vehicle_id
	 *
	 * @returns {Observable}
	 */
	getVehicle(vehicle_id: number): Observable<any> {
		return this.configure_api_service
			.getVehicle(vehicle_id)
			.pipe(
				map((response: HttpResponse<any>) => {
					if (response.ok) {
						const configure_data: any = ConfigureStruct;
						configure_data.steps.purchase_vehicle.data = response.body;
						configure_data.meta.finance.available = response.body.regular_payment !== null;

						this.data_layer_service.push({ event: 'vehicleData', vehicleData: response.body });
						return configure_data;
					}

					return null;
				})
			)
			.pipe(
				catchError((response: HttpErrorResponse) => {
					this.error_service.show(response.status, response, 'app/core/resolvers/resolvers :: getVehicle(' + vehicle_id + ')');
					return of(null);
				})
			);
	}

	/**
	 * Gets enquiry from enquiry hash.
	 *
	 * @param {string} key
	 * @param {string} step_mode
	 *
	 * @returns {Observable}
	 */
	getEnquiry(key: string, step_mode: string = null, vehicle_id: number = null): Observable<any> {
		return this.configure_api_service.getConfigureData(key, step_mode, vehicle_id).pipe(
			map((response: HttpResponse<any>) => {
				if (response.ok) {
					return response.body;
				}

				return null;
			})
		);
	}

	/**
	 * Gets obfuscated customer info.
	 *
	 * @param {string} key
	 *
	 * @returns {Observable}
	 */
	getObfuscatedCustomer(key: string): Observable<any> {
		return this.core_api_service
			.obfuscate(key)
			.pipe(
				map((response: HttpResponse<any>) => {
					if (response.ok) {
						return response.body;
					}

					return null;
				})
			)
			.pipe(
				catchError((response: HttpErrorResponse) => {
					if (response.status === 410) {
						this.error_service.show(410, null, null);
						return of(null);
					}

					this.error_service.show(
						response.status,
						response,
						'app/core/resolvers/resolvers :: getObfuscatedCustomer(' + key + ')'
					);
					return of(null);
				})
			);
	}
}
