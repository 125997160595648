<div *ngIf="error" class="error">
	<div class="error-backdrop"></div>
	<div class="error-frame">
		<div class="error-header pri-bg">
			<h2 class="pri-fg">{{ error.title }}</h2>
		</div>
		<div class="error-body">
			<div class="icon-message">
				<i [ngClass]="error.icon"></i>
				<div class="written-content" [ngSwitch]="error.status_code">
					<div *ngSwitchCase="401" class="written-content">
						<h3>You might need to log in.</h3>
						<p>
							You need to be logged in to access that resource. Please
							<strong>
								<a href="https://myclickdealer.co.uk/dealer_interface_login.php" class="text-hl">
									Log In
								</a>
							</strong>
							in and try again.
						</p>
					</div>
					<div *ngSwitchCase="403" class="written-content">
						<h3>You're not authorised to access that resource.</h3>
						<p>It looks like you're trying to access a resource you don't have access to.</p>
					</div>
					<div *ngSwitchCase="404" class="written-content">
						<h3>Sorry, we couldn't find what you were looking for.</h3>
						<p>
							It might be that the resource you were looking for has moved or the address was typed
							incorrectly. Please try again.
						</p>
					</div>
					<div *ngSwitchCase="410" class="written-content">
						<h3>Sorry, that vehicle is no longer available.</h3>
						<p *ngIf="dealer.website_url">
							We have a large number of other vehicles that might suit your needs, to view our current
							stock please check our website.
						</p>
						<a *ngIf="dealer.website_url" class="button positive-button" [href]="dealer.website_url">
							Visit Website
						</a>
					</div>
					<div *ngSwitchCase="503" class="written-content">
						<h3>We're working on it!</h3>
						<p>
							This app is currently under maintenance. We apologise for any inconvenience and we hope to
							be back up and running as soon as possible.
						</p>
					</div>
					<div *ngSwitchDefault class="written-content">
						<h3>Something went wrong.</h3>
						<p>
							There was a problem with our service. We have automatically sent an error report so that we
							can investigate. Apologies for any inconvenience.
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
