import { Injectable } from '@angular/core';

import { ModalService } from '@app/core/services/modal.service';
import { APIService } from '@app/core/services/api.service';
import { Observable } from 'rxjs';

@Injectable()
export class DemonstrationService {
	/**
	 * Constructs class and injects dependencies.
	 *
	 * @param {ModalService} modal_service
	 * @param {APIService} api_service
	 */
	constructor(private modal_service: ModalService, private api_service: APIService) {}

	/**
	 * The modal to select the finance options
	 *
	 * @param quote
	 * @return void
	 */
	previewFinanceUpdate(quote: any): void {
		this.modal_service.show(['configure/demo-finance-update'], { quote });
	}

	/**
	 * Send the demonstration update
	 *
	 * @param body
	 * @return void
	 */
	sendDemonstrationUpdate(body): Observable<any> {
		return this.api_service.postOrPutRequest('post', 'demonstration/update-finance', body);
	}
}
