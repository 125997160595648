export const ConfigureStruct = {
	placeholder: true,
	meta: {
		enquiry_id: null,
		enquiry_hash: null,
		latest_step: null,
		current_step: null,
		pex_asking_price: null,
		pex_instant_valuation: null,
		customer: {
			first_name: null,
			last_name: null,
			email: null,
			tel_mob: null,
		},
		callback: {},
		finance: {
			available: null,
			enabled: null,
			failed: null,
			mileage: null,
			deposit: null,
			term: null,
		},
		cashback_deal: false,
		utm_source: null,
	},

	steps: {
		purchase_vehicle: {
			name: 'Your New Vehicle',
			route: 'your-new-vehicle',
			data: null,
		},
		part_exchange: {
			name: 'Part Exchange',
			route: 'part-exchange',
			data: [],
		},
		customise_review: {
			name: 'Customise & Review',
			route: 'customise-review',
			data: null,
		},
		finance_options: {
			name: 'Finance Options',
			route: 'finance-options',
			data: null,
		},
		delivery_options: {
			name: 'Delivery Options',
			route: 'delivery-options',
			data: null,
		},
		payment: {
			name: 'Payment Options',
			route: 'payment-options',
			data: {
				callback: null,
			},
		},
	},
};
