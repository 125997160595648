import { HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { Observable, of } from 'rxjs';

import { StorageService } from './storage.service';
import { ErrorService } from './error.service';

@Injectable()
export class APIUtilityService {
	constructor(private storage_service: StorageService, private error_service: ErrorService, private router: Router) {}

	/**
	 * Gets headers needed for configure to run in enquiry mode. (Adds token header).
	 *
	 * @param {string} enquiry_hash
	 * @returns {Headers}
	 */
	getEnquiryHeaders(enquiry_hash: string): HttpHeaders {
		let headers: HttpHeaders = new HttpHeaders();

		const token: string = this.storage_service.getItem('token-' + enquiry_hash);

		if (token !== '') {
			headers = headers.append('Authorization', 'Bearer ' + token);
		}

		return headers;
	}

	/**
	 * Handles re-setting of new token after every request. Also listens for vehicle availability.
	 *
	 * @param {Observable} request
	 * @param {string} enquiry_hash
	 * @param {boolean} triggers_soft_error
	 *
	 * @param return_raw_response
	 * @returns {Observable}
	 */
	intercept(
		request: Observable<any>,
		enquiry_hash: string = null,
		unauthorized_redirect_path: string[] = [],
		triggers_soft_error: boolean = false,
		return_raw_response: boolean = false,
		query_params: Params = null,
	): Observable<any> {
		return request
			.pipe(
				map((response: any) => {
					if (enquiry_hash !== null && response.headers.get('Authorization')) {
						this.storage_service.setItem(
							'token-' + enquiry_hash,
							response.headers.get('Authorization').replace('Bearer ', '')
						);
					}

					return response;
				})
			)
			.pipe(
				catchError((response: HttpErrorResponse): any => {
					if (enquiry_hash !== null && response.headers.get('Authorization')) {
						this.storage_service.setItem(
							'token-' + enquiry_hash,
							response.headers.get('Authorization').replace('Bearer ', '')
						);
					}

					if (!triggers_soft_error) {
						switch (response.status) {
							case 401:
								if (enquiry_hash !== null) {
									this.storage_service.removeItem('token-' + enquiry_hash);
									if (unauthorized_redirect_path.length) {
										this.router.navigate(unauthorized_redirect_path, { queryParams: query_params });
									} else {
										this.error_service.show(401, null, null);
									}
								}
								break;
							case 410:
								this.error_service.show(410, null, null);
								break;
							default:
								this.error_service.show(
									response.status,
									response,
									'APIUtilityService.intercept()',
									response.error
								);
								break;
						}
					}

					if (return_raw_response) {
						return of(response);
					}

					return of(false);
				})
			);
	}
}
