import { Injectable } from '@angular/core';

@Injectable()
export class IFrameCommunicationsService {
	/**
	 * Sends message to parent
	 * @param message
	 */
	private sendToParent(message: any): void {
		setTimeout(() => {
			window.parent.postMessage(JSON.stringify(message), '*');
		});
	}

	/**
	 * Send domain event
	 * @param event
	 * @param data
	 */
	sendDomainEvent(event: any, data: any): void {
		this.sendToParent({ domain: { event: event, data: data } });
	}

	/**
	 * Send domain event
	 * @param event
	 * @param data
	 */
	sendUserEvent(event: any, data: any): void {
		this.sendToParent({ user: { event: event, data: data } });
	}

	/**
	 * Communicates height to parent window.
	 */
	communicateHeight(height: number): void {
		this.sendToParent({ height: height });
	}
}
