import { Injectable } from '@angular/core';
import * as bowser from 'bowser';

import { PageScrollService } from 'ngx-page-scroll-core';

import { environment } from '@environments/environment';

@Injectable()
export class HelperService {
	constructor(private page_scroll_service: PageScrollService) {}
	/**
	 * Builds url for vehicle manufacturer logo.
	 *
	 * @param {string} manufacturer
	 *
	 * @returns {string}
	 */
	buildManufacturerBrandLogoUrl(manufacturer: string): string {
		return environment.brand_image_url + manufacturer.toLowerCase().replace(new RegExp(' ', 'g'), '-') + '.png';
	}

	/**
	 * Builds url for addon provider logo.
	 *
	 * @param {string} filename
	 *
	 * @returns {string}
	 */
	buildAddonBrandLogoUrl(filename: string): string {
		return environment.brand_image_url + filename;
	}

	/**
	 * Builds common separated address from array of strings
	 *
	 * @param {Array<string>} lines
	 * @param {boolean} break_line
	 *
	 * @returns {string}
	 */
	buildAddress(lines: string[], break_line: boolean): string {
		let address: string = '';
		const br: string = break_line ? '<br>' : '';
		for (const l of lines) {
			if (l !== '' && l !== null && l !== 'undefined' && l !== undefined) {
				address += address !== '' ? ', ' + br + l : l;
			}
		}
		return address;
	}

	/**
	 * Returns copy of any object
	 *
	 * @param {} object
	 *
	 */
	copy(object: any): any {
		return JSON.parse(JSON.stringify(object));
	}

	/**
	 * Compares two arrays and returns whether or not they match.
	 *
	 * @param {Array} a1
	 * @param {Array} a2
	 *
	 * @returns {boolean}
	 */
	arraysMatch(a1: any[], a2: any[]): boolean {
		if (typeof a1 === 'undefined' && typeof a2 === 'undefined') {
			return true;
		}

		if (typeof a1 !== typeof a2) {
			return false;
		}

		if (a1.length !== a2.length) {
			return false;
		}

		return JSON.stringify(a1) === JSON.stringify(a2);
	}

	/**
	 * Converts RGB value to hex value.
	 *
	 * @param rgb
	 * @param {boolean} include_hash
	 *
	 * @returns {string}
	 */
	rgbToHex(rgb: any, include_hash: boolean): string {
		rgb = rgb.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
		const hash: string = include_hash ? '#' : '';
		return rgb && rgb.length === 4
			? hash +
					('0' + parseInt(rgb[1], 10).toString(16)).slice(-2) +
					('0' + parseInt(rgb[2], 10).toString(16)).slice(-2) +
					('0' + parseInt(rgb[3], 10).toString(16)).slice(-2)
			: '';
	}

	/**
	 * Generates random string at a specified length. Always starts with a letter, not a number.
	 *
	 * @param {number} length
	 *
	 * @returns {string}
	 */
	generateRandomString(length: number): string {
		const possible: string = 'abcdefghijklmnopqrstuvwxyz0123456789';
		const possibleFirst: string = 'abcdefghijklmnopqrstuvwxyz';
		let randomString: string = possibleFirst.charAt(Math.floor(Math.random() * possibleFirst.length));

		for (let i: number = 0; i < length - 1; i++) {
			randomString += possible.charAt(Math.floor(Math.random() * possible.length));
		}

		return randomString;
	}

	/**
	 * Checks browser support for passed feature.
	 *
	 * @param {string} feature
	 * @returns {boolean}
	 */
	browserSupports(feature: string): boolean {
		switch (feature) {
			case 'swiper.update':
				return !(bowser.msie && bowser.version < 11);
			case 'sliders':
				return !(bowser.safari && bowser.version < 9);
		}

		return true;
	}

	/**
	 * Checks current screen size and returns boolean based on parameters passed.
	 *
	 * @param {string} operator
	 * @param {string} screen_size
	 * @returns {boolean}
	 */
	isScreenWidth(operator: string, screen_size: string): boolean {
		const screen_widths: { key: string; min: number; max: number }[] = [
			{ key: 'xxs', min: 0, max: 374 },
			{ key: 'xs', min: 375, max: 639 },
			{ key: 'sm', min: 640, max: 1019 },
			{ key: 'md', min: 1020, max: 1365 },
			{ key: 'lg', min: 1366, max: 1699 },
			{ key: 'xl', min: 1700, max: 999999 },
		];

		const screen_width: { key: string; min: number; max: number } = screen_widths.filter(
			(size: { key: string; min: number; max: number }) => {
				return size.key === screen_size;
			}
		)[0];

		switch (operator) {
			case '<=':
				return window.innerWidth < screen_width.max;

			case '>=':
				return window.innerWidth > screen_width.min;
			case '==':
				return window.innerWidth > screen_width.min && window.innerWidth < screen_width.max;
			default:
				return true;
		}
	}

	/**
	 * Jumps scroll position to top of window.
	 */
	scrollTop(): void {
		window.scrollTo(0, 0);
	}

	/**
	 * Scrolls the view to the section related to the clicked item.
	 *
	 * @param target The selector of the element.
	 * @param offset The number of pixels to offset the screen at.
	 */
	smoothScrollTo(target: string, offset = 0): void {
		this.page_scroll_service.scroll({
			document,
			scrollTarget: target,
			scrollOffset: offset,
		});
	}

	/**
	 * Locks current scroll position and hides scroll bar.
	 *
	 * @param {number} offset
	 * @param {number} cssDelay
	 */
	scrollLock(offset: number = 0, cssDelay: number = 0): void {
		if (!bowser.ios) {
			document.body.style.top = -offset + 'px';
		}

		setTimeout(() => {
			document.body.classList.add('scroll-lock');
		}, cssDelay);
	}

	/**
	 * Unlocks scroll and allows user scroll.
	 *
	 * @param {number} offset
	 */
	scrollUnlock(offset: number = 0): void {
		document.body.classList.remove('scroll-lock');
		window.scrollTo(0, offset);

		if (!bowser.ios) {
			document.body.style.top = '0';
		}
	}

	/**
	 * Checks if number id odd
	 *
	 * @param number
	 */
	isOdd(number: number): boolean {
		return number % 2 === 1;
	}

	/**
	 * Convert strings between different cases.
	 */
	convertCase(text: string, originalCase: string, desiredCase: string): string {
		if ((originalCase === 'camel' || originalCase === 'pascal') && desiredCase === 'title') {
			text = text.replace(/(^|\s)[a-z]/g, (f: string) => {
				return f.toUpperCase();
			});

			return text.replace(/([^A-Z])([A-Z])/g, '$1 $2');
		}

		if (originalCase === 'title' && desiredCase === 'kebab') {
			return text.toLowerCase().replace(/ /g, '-');
		}

		return text;
	}

	getFinanceStatus(status): string {
		if (status) {
			if (
				status.description === 'CompletedDeal' ||
				status.description === 'Paid out (Awaiting originals)' ||
				status.description === 'Paid out (Originals received)'
			) {
				return 'Completed';
			}

			if (status.description === 'UnableToAssist') {
				return 'Rejected';
			}

			if (
				status.description === 'NotTakenUp' ||
				status.description === 'Not taken up' ||
				status.description === 'Withdrawn/Cancelled'
			) {
				return 'Cancelled';
			}

			if (status.description === 'Auto-Accept') {
				return 'Accepted';
			}

			if (status.description === 'Auto-Declined' || status.description === 'Instant Declined') {
				return 'Declined';
			}

			if (
				status.description === 'Payout pending' ||
				status.description === 'Payout with funder' ||
				status.description === 'Payout received (Docs received)' ||
				status.description === 'Payout error' ||
				status.description === 'PendingPayout' ||
				status.description === 'E-Sign Email Sent'
			) {
				return 'Approved';
			}

			const submittedTypes: string[] = [
				'Awaiting Underwrite',
				'Auto-route',
				'Application with underwriting',
				'Application with funder',
				'Application with specialist funder',
				'Application in progress',
				'Referred',
				'ReferredToBroker',
				'ReferredToUnderwriter',
			];

			if (submittedTypes.includes(status.description)) {
				return 'Submitted';
			}

			return status.description;
		}

		return null;
	}

	getFinanceStatusIcon(status): string {
		switch (status) {
			case 'Quote':
				return 'fas fa-pound-sign pri';
			case 'Proposed':
			case 'Reproposed':
			case 'WaitingFurtherInformation':
			case 'AwaitingResubmission':
			case 'AwaitingDocuments':
			case 'Awaiting amendment':
			case 'Amendment with funder':
			case 'PendingPayout':
			case 'Submitted':
			case 'Information required':
				return 'fas fa-clock pri';
			case 'Accepted':
			case 'Auto-Accept':
			case 'Approved':
			case 'ConditionallyApproved':
			case 'Completed':
				return 'fas fa-check-circle pos';
			case 'SubmissionFailed':
			case 'UnableToAssist':
			case 'Payout Error':
				return 'fas fa-exclamation-circle neg';
			case 'Referred':
			case 'ReferredToBroker':
			case 'ReferredToUnderwriter':
				return 'fas fa-user-clock pri';
			case 'NotTakenUp':
			case 'Cancelled':
			case 'Rejected':
			case 'Declined':
			case 'Instant Declined':
			case 'Auto-Declined':
			case 'Inactive':
			case 'NotEligible':
				return 'fas fa-times-circle neg';
			default:
				return null;
		}
	}
}
