import { Params, Router, RouterEvent, NavigationEnd } from '@angular/router';
import { Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';

import { DataLayerService } from '@app/core/services/data-layer.service';
import { SessionService } from '@app/core/services/session.service';
import { StorageService } from '@app/core/services/storage.service';

import { StructuredEvent } from '@app/core/models/structured-event.model';
import { Dealer } from '@app/core/models/dealer.model';

declare const fbq: any;

@Injectable()
export class TrackingService {
	dealer: Dealer = this.session_service.getDealer();
	facebook_enabled: boolean = false;
	dnt: boolean = this.storage_service.getItem('dnt') === 'true';

	/**
	 * Construct the Tracking Service.
	 */
	constructor(
		private session_service: SessionService,
		private storage_service: StorageService,
		private data_layer_service: DataLayerService,
		private router: Router
	) {
		if (!this.dnt && this.dealer.facebook_pixel_id) {
			fbq('init', this.dealer.facebook_pixel_id);
			this.facebook_enabled = true;
			this.router.events.pipe(filter((event: RouterEvent) => event instanceof NavigationEnd)).subscribe(() => {
				fbq('track', 'PageView');
			});
		}
	}

	/**
	 * Checks params
	 * @param params
	 */
	paramsExist(params: Params): boolean {
		return JSON.stringify(params) !== '{}';
	}

	/**
	 * Set the params
	 *
	 * @param {string[]} params
	 */
	setParams(params: Params): void {
		this.storage_service.setItem('params', JSON.stringify(params));
	}

	/**
	 * Get tracking params
	 *
	 * @returns {string}
	 */
	getParams(): Params {
		const params: string = this.storage_service.getItem('params');

		if (params) {
			return JSON.parse(params);
		}

		return {};
	}

	/**
	 * Tracks when there is a referrer without a utm_source set.
	 */
	trackReferrerWithoutSource(referrer: string) {
		this.dispatchToDataLayer(
			'customEvent',
			new StructuredEvent(
				'engage_configure_referrer_without_source',
				'journey',
				'custom_event',
				'engage_configure_referrer_without_source[' + referrer + ']'
			)
		);
	}

	/**
	 * Tracks when there is a referrer sent.
	 */
	trackReferrer(referrer: string) {
		this.dispatchToDataLayer(
			'customEvent',
			new StructuredEvent('engage_configure_referrer', 'journey', 'custom_event', 'engage_configure_referrer[' + referrer + ']')
		);
	}

	/**
	 * Track the start of engage.
	 */
	trackStart(): void {
		this.dispatchToDataLayer(
			'customEvent',
			new StructuredEvent('engage_configure_started', 'journey', 'custom_event', 'engage_configure_started')
		);
		this.dispatchToFacebook(new StructuredEvent('engage_configure_started', 'Engage Start', 'start', 'Engage Start'));
	}

	/**
	 * Track when a customer reaches the completed stage of the part exchange step.
	 */
	trackPartExchangeCompleted(): void {
		this.dispatchToDataLayer(
			'customEvent',
			new StructuredEvent(
				'engage_configure_part_exchange_completed',
				'journey',
				'custom_event',
				'engage_configure_part_exchange_completed'
			)
		);
		this.dispatchToFacebook(new StructuredEvent('engage_configure_part_exchange_completed', 'Part Exchange', 'submit', ''));
	}

	/**
	 * Tracks when customer leaves engage to apply for finance.
	 */
	trackFinanceApplyIntent(): void {
		this.dispatchToDataLayer(
			'customEvent',
			new StructuredEvent('engage_configure_finance_apply_intent', 'journey', 'custom_event', 'engage_configure_finance_apply_intent')
		);
		this.dispatchToFacebook(new StructuredEvent('engage_configure_finance_apply_intent', 'Finance', 'click', 'Applying for Finance'));
	}

	/**
	 * Tracks when customer submits the internal finance application form.
	 */
	trackFinanceApplySubmit(): void {
		this.dispatchToDataLayer(
			'customEvent',
			new StructuredEvent('engage_configure_finance_apply_submit', 'journey', 'custom_event', 'engage_configure_finance_apply_submit')
		);
	}

	/**
	 * Track a reservation payment.
	 */
	trackReservationPayment(reservation_amount: number): void {
		this.dispatchToDataLayer(
			'customEvent',
			new StructuredEvent(
				'engage_configure_reservation',
				'journey',
				'custom_event',
				'engage_configure_reservation',
				reservation_amount
			)
		);

		fbq('track', 'Purchase', {
			value: reservation_amount,
			currency: 'GBP',
			content_name: this.dealer.friendly_name + ' - Reservation',
		});
	}

	/**
	 * Track a full payment.
	 */
	trackFullPayment(amount: number): void {
		this.dispatchToDataLayer(
			'customEvent',
			new StructuredEvent('engage_configure_full_payment', 'journey', 'custom_event', 'engage_configure_full_payment', amount)
		);
	}

	/**
	 * Track a callback request.
	 */
	trackCallback(): void {
		this.dispatchToDataLayer(
			'customEvent',
			new StructuredEvent('engage_configure_callback_arranged', 'journey', 'custom_event', 'engage_configure_callback_arranged')
		);
		this.dispatchToFacebook(new StructuredEvent('engage_configure_callback_arranged', 'Callback', 'callback', 'Callback'));
	}

	/**
	 * Track when the customer updates their details.
	 */
	trackCustomerDetailsUpdate(): void {
		this.dispatchToDataLayer(
			'customEvent',
			new StructuredEvent(
				'engage_configure_custom_details_updated',
				'journey',
				'custom_event',
				'engage_configure_customer_details_updated'
			)
		);
	}

	/**
	 * Track when the customer saves for later.
	 */
	trackSaveForLater(): void {
		this.dispatchToDataLayer(
			'customEvent',
			new StructuredEvent(
				'engage_configure_customer_save_for_later',
				'journey',
				'custom_event',
				'engage_configure_customer_saved_for_later'
			)
		);
		this.dispatchToFacebook(new StructuredEvent('engage_configure_customer_save_for_later', 'Update', 'Update', 'Send Link'));
	}

	/**
	 * Tracks input on form.
	 */
	trackFormInput(form_name: string, input_type: string, input_name: string): void {
		this.dispatchToDataLayer(
			'formEvent',
			new StructuredEvent('engage_form_input', 'engage_' + form_name, 'input', input_type + `[${input_name}]`)
		);
	}

	/**
	 * Tracks when a user attempts form submission
	 */
	trackFormSubmitAttempt(form_name: string, completed_fields: string[], empty_fields: string[]): void {
		this.dispatchToDataLayer(
			'formEvent',
			new StructuredEvent(
				'engage_submit_attempt',
				'engage_' + form_name,
				'submit_attempt',
				'completed_fields[' + completed_fields.join(',') + ']|empty_fields[' + empty_fields.join(',') + ']'
			)
		);
	}

	/**
	 * Tracks when a user failed form submission due to validation.
	 */
	trackFormValidationFailure(form_name: string, failure_reason: string = null): void {
		this.dispatchToDataLayer(
			'formEvent',
			new StructuredEvent('engage_form_validation_failure', 'engage_' + form_name, 'validation_failure', failure_reason)
		);
	}

	/**
	 * Tracks when a user successfully passed client side validation.
	 */
	trackFormValidationPass(form_name: string): void {
		this.dispatchToDataLayer('formEvent', new StructuredEvent('engage_form_validation_pass', 'engage_' + form_name, 'validation_pass'));
	}

	/**
	 * Tracks when a user successfully submitted the form to the server.
	 */
	trackFormSubmitSuccess(form_name: string): void {
		this.dispatchToDataLayer('formEvent', new StructuredEvent('engage_form_submit_success', 'engage_' + form_name, 'submit_success'));
	}

	/**
	 * Tracks when a user submitted the form but the request failed on the server.
	 */
	trackFormSubmitFailure(form_name: string, failure_reason: string = null): void {
		this.dispatchToDataLayer(
			'formEvent',
			new StructuredEvent('engage_form_submit_failure', 'engage_' + form_name, 'submit_failure', failure_reason)
		);
	}

	/**
	 * Track a custom event with passed label.
	 */
	trackCustomEvent(label: string, name?: string): void {
		this.dispatchToDataLayer('customEvent', new StructuredEvent(name || label, 'journey', 'custom_event', label));
	}

	/**
	 * Dispatch Job to DataLayer.
	 */
	protected dispatchToDataLayer(event_name: string, structured_event: StructuredEvent): void {
		if (!this.dnt) {
			this.data_layer_service.push({ event: event_name, structuredEvent: structured_event });
		}
	}

	/**
	 * Dispatch Job to Facebook.
	 */
	protected dispatchToFacebook(structured_event: StructuredEvent): void {
		if (this.facebook_enabled) {
			fbq('trackCustom', this.dealer.friendly_name + ' - ' + structured_event.label, {
				category: structured_event.category,
				action: structured_event.action,
			});
		}
	}
}
