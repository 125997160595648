import { CommonModule, DecimalPipe } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSliderModule } from '@angular/material/slider';
import { NgModule } from '@angular/core';
import 'hammerjs';

import { RadioButtonControlComponent } from './components/radio-button-control/radio-button-control.component';
import { DatePickerControlComponent } from './components/date-picker-control/date-picker-control.component';
import { FileUploadControlComponent } from './components/file-upload-control/file-upload-control.component';
import { CheckboxControlComponent } from './components/checkbox-control/checkbox-control.component';
import { SelectControlComponent } from './components/select-control/select-control.component';
import { SliderControlComponent } from './components/slider-control/slider-control.component';
import { TextControlComponent } from './components/text-control/text-control.component';

import { AutosizeDirective } from './directives/autosize.directive';

@NgModule({
	declarations: [
		RadioButtonControlComponent,
		DatePickerControlComponent,
		FileUploadControlComponent,
		CheckboxControlComponent,
		SelectControlComponent,
		SliderControlComponent,
		TextControlComponent,

		AutosizeDirective,
	],
	imports: [CommonModule, ReactiveFormsModule, MatSliderModule],
	exports: [
		ReactiveFormsModule,

		RadioButtonControlComponent,
		DatePickerControlComponent,
		FileUploadControlComponent,
		CheckboxControlComponent,
		SelectControlComponent,
		SliderControlComponent,
		TextControlComponent,

		AutosizeDirective,
	],
	providers: [DecimalPipe],
})
export class UIFormsModule {}
