import { Injectable } from '@angular/core';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

import { environment } from '@environments/environment';

@Injectable()
export class PusherService {
	private echo: any;

	private defaultConfig: any = {
		broadcaster: 'pusher',
		key: environment.pusher_key,
		cluster: 'eu',
		encrypted: true,
		namespace: false,
	};

	/**
	 * Constructs class and injects dependencies. Initialises echo.
	 */
	constructor() {
		window['Pusher'] = Pusher;
		this.echo = new Echo(this.defaultConfig);
	}

	/**
	 * Sets pusher config.
	 *
	 * @param overrideConfig
	 */
	setConfig(overrideConfig: any): void {
		this.echo = new Echo(Object.assign(this.defaultConfig, overrideConfig));
	}

	/**
	 * Joins pusher channel.
	 *
	 * @param {string} channelName
	 *
	 * @returns {}
	 */
	join(channelName: string): any {
		return this.echo.join(channelName);
	}

	/**
	 * Subscribes to pusher channel.
	 *
	 * @param {string} channelName
	 *
	 * @returns {}
	 */
	subscribe(channelName: string): any {
		return this.echo.subscribe(channelName);
	}

	/**
	 * Returns socket id.
	 *
	 * @returns {string}
	 */
	socketId(): string {
		return this.echo.socketId();
	}
}
